import { Component } from "react";

import ConfettiExplosion from "react-confetti-explosion";
import Swal from "sweetalert2";

import {
    AGFilterRender,
    AGGrid,
    DataPreferences,
    MapboxMap,
    ToggleGroup,
} from "components";
import { UserContext } from "context";
import { Button, DeleteButton, Form, Loading, Modal, Select, TextInput } from "library";

import {
    ajax_wrapper,
    format_metric_value,
    get_filtered_region_data,
    get_url,
    isErrorResponse,
    post,
} from "functions";

import { ACRE_RANGES, GEO_SCALES, METRIC_KEYS, RANGE_PAIRS } from "constants";

class TrackButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: null,
            disabled: false,
            exploding: false,
        };

        this.check_markets = this.check_markets.bind(this);
        this.track_market = this.track_market.bind(this);
        this.untrack_market = this.untrack_market.bind(this);
    }

    componentDidMount() {
        this.check_markets();
    }

    componentDidUpdate(prevProps) {
        if (this.props.markets != prevProps.markets) {
            this.check_markets();
        }
    }

    check_markets() {
        const regionID = this.props.data["id"];
        const market = this.props.findMarketByRegionID(regionID);
        this.setState({
            id: market?.id,
            disabled: false,
        });
    }

    async track_market() {
        this.setState({ exploding: true, disabled: true });

        const regionID = this.props.data["id"];
        const market = await this.props.trackMarket(regionID);

        this.setState({ id: market.id, disabled: false });
    }

    untrack_market() {
        this.props.refresh_markets();
        this.setState({ id: null });
    }

    render() {
        let button = (
            <Button
                onClick={this.track_market}
                type="gradient-secondary"
                disabled={this.state.disabled}
            >
                <i className="fa fa-plus"></i> Add
            </Button>
        );

        if (this.state.id) {
            button = (
                <DeleteButton
                    text={"Remove"}
                    url="/api/markets/"
                    value={this.state.id}
                    callback={this.untrack_market}
                    modalEnabled={this.props.deleteModalEnabled}
                />
            );
        }

        return (
            <>
                {this.state.exploding && (
                    <ConfettiExplosion
                        onComplete={() =>
                            this.setState({
                                exploding: false,
                            })
                        }
                    />
                )}
                {button}
            </>
        );
    }
}

export default class Dashboard extends Component {
    static contextType = UserContext;

    state = {
        error: "",

        map_filter_data: {
            geo_scale: GEO_SCALES[0],
            acre_range: "0.2 acre-100 acre",
            visual_field: "Active",

            geocoding_input: "",
        },

        region_data: [],
        markets: [],

        columns: [],
        table_rows: [],

        filter_data: {},
        saving_filter: null,
        editing_preferences: false,

        data_timestamp: null,
    };

    constructor(props) {
        super(props);
        this.refresh_markets = this.refresh_markets.bind(this);
        this.get_region_data = this.get_region_data.bind(this);
        this.change_scope = this.change_scope.bind(this);
        this.handle_filter_change = this.handle_filter_change.bind(this);
        this.reset_filters = this.reset_filters.bind(this);
        this.highlight_region = this.highlight_region.bind(this);
        this.fire_scope_reminder = this.fire_scope_reminder.bind(this);
        this.getColumns = this.getColumns.bind(this);
        this.getRows = this.getRows.bind(this);
        this.updateTable = this.updateTable.bind(this);
        this.getFilters = this.getFilters.bind(this);
        this.setFilters = this.setFilters.bind(this);
        this.clearMetricKeyFilters = this.clearMetricKeyFilters.bind(this);
        this.onFullscreenChange = this.onFullscreenChange.bind(this);
        this.trackMarket = this.trackMarket.bind(this);
        this.untrackMarket = this.untrackMarket.bind(this);
        this.toggleSavedMarket = this.toggleSavedMarket.bind(this);
        this.findMarketByRegionID = this.findMarketByRegionID.bind(this);
    }

    componentDidMount() {
        document.addEventListener("fullscreenchange", this.onFullscreenChange);

        this.get_region_data();

        let params = get_url();
        if ("filter" in params) {
            ajax_wrapper("GET", `/api/filters/${params["filter"]}/`, {}, (value) => {
                if (isErrorResponse(value)) {
                    return;
                }
                this.setFilters(value);
            });
        }
    }

    componentWillUnmount() {
        document.removeEventListener("fullscreenchange", this.onFullscreenChange);
    }

    onFullscreenChange(_e) {
        this.setState({ fullscreen: document.fullscreenElement != null });
    }

    refresh_markets() {
        ajax_wrapper("GET", "/api/markets/", {}, (value) => {
            if (isErrorResponse(value)) {
                return;
            }
            this.setState(
                {
                    markets: value,
                },
                this.updateTable,
            );
        });
    }

    get_region_data() {
        let data = Object.assign({}, this.state.map_filter_data);
        if (!data["geo_scale"] || !data["acre_range"]) {
            return false;
        }

        ajax_wrapper(
            "GET",
            `/get_region_data/${data["geo_scale"]}/${data["acre_range"]}/`,
            {},
            (value) => {
                if (isErrorResponse(value)) {
                    return;
                }
                this.setState(
                    {
                        region_data: value.data,
                        data_timestamp: Date.now(),
                        loaded: true,
                    },
                    this.refresh_markets,
                );
            },
        );
    }

    async change_scope(key, value) {
        const { map_filter_data, filter_data } = this.state;
        if (map_filter_data[key] === value) {
            return;
        }

        const newMapFilterData = { ...map_filter_data };
        newMapFilterData[key] = value;

        if (["visual_field", "geocoding_input"].includes(key)) {
            this.setState({
                map_filter_data: newMapFilterData,
                data_timestamp: Date.now(),
            });
            return;
        }

        const has_table_filters = Object.keys(filter_data).length > 0;

        if (has_table_filters && key == "geo_scale") {
            const result = await this.fire_scope_reminder();
            if (result.isDismissed) {
                return;
            }
            this.clearMetricKeyFilters();
        }

        this.setState(
            {
                map_filter_data: newMapFilterData,
                loaded: false,
            },
            this.get_region_data,
        );
    }

    clearMetricKeyFilters() {
        const { filter_data } = this.state;
        const { geo_scale } = this.state.map_filter_data;

        // Filters to preserve when switching between ZIP and County
        const keep = ["sorting", "order", "state", "name", "county_name"];

        // Filters to rename when switching between ZIP and County
        const rename = {
            ZIP: { county_name: "name" },
            County: { name: "county_name" },
        }[geo_scale];

        const newFilterData = {};

        for (let key in filter_data) {
            if (keep.includes(key)) {
                const newKey = rename[key] || key;
                newFilterData[newKey] = filter_data[key];
            }
        }

        this.setState({ filter_data: newFilterData });
    }

    fire_scope_reminder() {
        const zip_text =
            "When moving from County to ZIP level data, your table filters will be cleared, since ZIP code data generally has smaller metric values.";
        const county_text =
            "When moving from Zip to County level data, your table filters will be cleared, since County data generally has larger metric values.";

        const { geo_scale } = this.state.map_filter_data;
        const text = geo_scale == "ZIP" ? zip_text : county_text;

        return Swal.fire({
            title: "Are you sure?",
            text,
            icon: "question",
            confirmButtonText: "OK",
            showCancelButton: true,
        });
    }

    getRows() {
        let table_rows = [];
        let acrage_key = this.state.map_filter_data["acre_range"];

        if (this.props.viewingMyMarketsPage) {
            for (let item of this.state.markets) {
                let stats = item["region"][acrage_key];
                if (!(acrage_key in item["region"])) {
                    stats = this.make_empty_row(METRIC_KEYS);
                }
                if (
                    (item["region"]["type"] == "county" &&
                        this.state.map_filter_data["geo_scale"] != "County") ||
                    (item["region"]["type"] == "zip" &&
                        this.state.map_filter_data["geo_scale"] != "ZIP")
                ) {
                    continue;
                }

                let row = {
                    id: item["region"]["id"],
                    gid: item["region"]["gid"],
                    name: item["region"]["name"],
                    county_name: item["region"]["county_name"],
                    state: item["region"]["state"],
                    out_of_county: item["region"]["out_of_county"],
                    out_of_state: item["region"]["out_of_state"],
                    ...stats,
                };
                table_rows.push(row);
            }
        } else {
            for (let item of this.state.region_data) {
                let stats = item[acrage_key];
                if (!(acrage_key in item)) {
                    stats = this.make_empty_row(METRIC_KEYS);
                }

                let row = {
                    id: item["id"],
                    gid: item["gid"],
                    name: item["name"],
                    county_name: item["county_name"],
                    state: item["state"],
                    out_of_county: item["out_of_county"],
                    out_of_state: item["out_of_state"],
                    ...stats,
                };
                table_rows.push(row);
            }
        }

        return table_rows;
    }

    make_empty_row(columns) {
        let row = {};
        for (let key of columns) {
            row[key] = 0;
        }
        return row;
    }

    handle_filter_change(data) {
        this.setState({
            filter_data: data,
            data_timestamp: Date.now(),
        });
    }

    reset_filters() {
        this.setState(
            {
                filter_data: {},
                data_timestamp: Date.now(),
            },
            this.updateTable,
        );
    }

    highlight_region(event) {
        if (event["column"]["colId"] == "id") {
            return false;
        }

        let input = `${event.data["name"].replace(" County", "")}, ${
            event.data["state"]
        }`;

        this.change_scope("geocoding_input", input);
    }

    // Return column definitions for Market Data table AG Grid
    getColumns() {
        const { user } = this.context;
        const data_point_names = user.data_point_preferences;

        const { geo_scale } = this.state.map_filter_data;

        // Generate columns for Market Data table
        const field_columns = [];
        for (let key of METRIC_KEYS) {
            if (!data_point_names.includes(key)) {
                continue;
            }
            field_columns.push({
                field: key,
                filter: true,
                filterParams: { buttons: ["apply"] },
                valueFormatter: (params) =>
                    format_metric_value(params["column"]["colId"], params["value"]),
            });
        }

        const region_columns = [
            {
                field: "state",
                filter: true,
                filterParams: { buttons: ["apply"] },
                pinned: "left",
            },
        ];

        if (geo_scale == "ZIP") {
            region_columns.push({
                field: "county_name",
                headerName: "County",
                filter: true,
                filterParams: { buttons: ["apply"] },
                pinned: "left",
            });
        }

        region_columns.push({
            field: "name",
            headerName: geo_scale === "ZIP" ? "ZIP" : "Name",
            filter: true,
            filterParams: { buttons: ["apply"] },
            pinned: "left",
        });

        const propstream_columns = [
            {
                field: "out_of_county",
                headerName: "Out Of County",
                filter: true,
                filterParams: { buttons: ["apply"] },
            },
            {
                field: "out_of_state",
                headerName: "Out Of State",
                filter: true,
                filterParams: { buttons: ["apply"] },
            },
        ];

        return [
            {
                field: "id",
                headerName: "Action",
                pinned: "left",
                cellRenderer: TrackButton,
                cellRendererParams: {
                    findMarketByRegionID: this.findMarketByRegionID,
                    deleteModalEnabled: this.props.viewingMyMarketsPage,
                    trackMarket: this.trackMarket,
                    refresh_markets: this.refresh_markets,
                    // Use markets param to trigger rerender after updating
                    // markets from map context menu
                    markets: this.state.markets,
                },
            },
            ...region_columns,
            ...field_columns,
            ...propstream_columns,
        ];
    }

    // Create a new SavedMarket and add a note to it for Campaigns
    async trackMarket(regionID) {
        const { user } = this.context;
        const { acre_range } = this.state.map_filter_data;

        try {
            // Create SavedMarket
            const market = await post("/api/markets/", {
                company_id: user.company_id,
                region_id: regionID,
            });
            // Add generated note for Campaigns
            await post(`/api/notes/`, {
                market_id: market.id,
                text: `Originally saved under the range ${acre_range}`,
            });
            this.refresh_markets();
            return market;
        } catch (xhr) {
            console.log(xhr);
        }
    }

    async untrackMarket(gid) {
        try {
            // Delete SavedMarket
            await post(`/api/markets/${gid}/`, null, { type: "DELETE" });
            this.refresh_markets();
        } catch (xhr) {
            console.log(xhr);
        }
    }

    async toggleSavedMarket(regionID) {
        const existingMarket = this.state.markets.find(
            (market) => market.region.id === regionID,
        );

        if (existingMarket) {
            await this.untrackMarket(existingMarket.id);
            return;
        }

        const newMarket = await this.trackMarket(regionID);
        return newMarket;
    }

    // Return SavedMarket by RegionID
    findMarketByRegionID(regionID) {
        for (let market of this.state.markets) {
            if (market.region && market.region.id === regionID) {
                return market;
            }
        }
        return null;
    }

    updateTable() {
        const reapplyFilters = () => {
            const filter_data = { ...this.state.filter_data };
            this.setState({ filter_data });
        };
        const columns = this.getColumns();
        const table_rows = this.getRows();
        this.setState({ columns, table_rows }, reapplyFilters);
    }

    // Return payload for Saved Filters data
    getFilters() {
        return {
            ...this.state.filter_data,
            map_filter_data: this.state.map_filter_data,
        };
    }

    // Set filters from Saved Filters data
    setFilters(savedFilter) {
        const data = { ...savedFilter.data };

        if (data.map_filter_data) {
            const map_filter_data = {
                ...data.map_filter_data,
                // Mapbox throws an exception if geocoding input is populated
                // before map is done loading
                geocoding_input: "",
            };
            this.setState({ map_filter_data });
            delete data.map_filter_data;
        }

        this.setState({ filter_data: data }, this.get_region_data);
    }

    render() {
        const { user, setUser } = this.context;
        const data_point_names = user.data_point_preferences;

        // Generate options for Heatmap dropdown and Tooltip Preferences
        const field_options = [];
        for (let key of METRIC_KEYS) {
            field_options.push({
                text: `${key}`,
                value: `${key}`,
            });
        }

        let acrage_key = this.state.map_filter_data["acre_range"];
        let field_key = this.state.map_filter_data["visual_field"];

        // Load region data
        const region_data = this.props.viewingMyMarketsPage
            ? this.state.markets.map((market) => newStyleRegionAdapter(market.region))
            : this.state.region_data;

        // Filter region data for map by table filter_data and Acreage Range
        const map_region_input = get_filtered_region_data(
            region_data,
            this.state.filter_data,
            acrage_key,
        );

        // Generate tooltip data for map features
        const map_lookup_data = {};
        for (let item of map_region_input) {
            const { id, name, state, type, county_name, gid } = item;
            const data = { id, name, state, type, county_name };
            for (let key of data_point_names) {
                const value = item[acrage_key] ? item[acrage_key][key] : 0;
                data[key] = value;
            }
            map_lookup_data[gid] = data;
        }

        // Generate heatmap color data for map based on selected Acreage Range
        // and Heatmap field
        const map_color_data = {};
        for (let item of map_region_input) {
            const value = item[acrage_key] ? item[acrage_key][field_key] : 0;
            map_color_data[item["gid"]] = value;
        }

        const invert_colors = field_key.includes("DOM") || field_key.includes("Gini");

        let save_filters_button = null;
        if (!this.props.viewingMyMarketsPage) {
            save_filters_button = (
                <Button
                    className="ms-2"
                    onClick={(_e) =>
                        this.setState({
                            saving_filter: {
                                company_id: user.company_id,
                                data: this.getFilters(),
                            },
                        })
                    }
                    type="gradient-primary"
                >
                    Save Filter
                </Button>
            );
        }

        let filter_modal = null;
        if (this.state.saving_filter) {
            filter_modal = (
                <Modal
                    show={true}
                    on_hide={() => this.setState({ saving_filter: null })}
                >
                    <div className="mb-3">
                        <div className="mb-3">Save Filter</div>
                        <div>
                            <AGFilterRender value={this.state.saving_filter.data} />
                        </div>
                    </div>
                    <Form
                        submit_url="/api/filters/"
                        defaults={this.state.saving_filter}
                        submit_success={function () {
                            this.setState({
                                saving_filter: null,
                            });
                        }.bind(this)}
                        submit_button_float={{ float: "right" }}
                    >
                        <TextInput name="name" label="Name Your Filter" />
                    </Form>
                </Modal>
            );
        }

        let data_preference_modal = null;
        if (this.state.editing_preferences) {
            data_preference_modal = (
                <Modal
                    show={true}
                    width={800}
                    on_hide={() => this.setState({ editing_preferences: false })}
                >
                    <div>
                        <h5>Tooltip Preferences</h5>
                    </div>
                    <DataPreferences
                        user={user}
                        data_options={field_options}
                        callback={(newUser) => {
                            setUser(newUser);
                            this.setState(
                                {
                                    editing_preferences: false,
                                    data_timestamp: Date.now(),
                                },
                                this.updateTable,
                            );
                        }}
                    />
                </Modal>
            );
        }

        const mapFilters = (
            <>
                <MapFilterCol title="Acreage Range">
                    <Select
                        className="mb-0"
                        options={RANGE_PAIRS.map(([_, __, key]) => {
                            return {
                                text: ACRE_RANGES[key],
                                value: key,
                            };
                        })}
                        name="acre_range"
                        value={this.state.map_filter_data["acre_range"]}
                        set_form_state={(state) =>
                            this.change_scope("acre_range", state["acre_range"])
                        }
                        no_blank_option={true}
                    />
                </MapFilterCol>
                <MapFilterCol title="Heatmap">
                    <Select
                        className="mb-0"
                        options={field_options}
                        name="visual_field"
                        value={this.state.map_filter_data["visual_field"]}
                        set_form_state={(state) =>
                            this.change_scope("visual_field", state["visual_field"])
                        }
                    />
                </MapFilterCol>
                <MapFilterCol title="Level">
                    <ToggleGroup
                        group_name="geo_scale"
                        on_change={this.change_scope}
                        options={GEO_SCALES}
                        selectedValue={this.state.map_filter_data["geo_scale"]}
                    />
                </MapFilterCol>
                {!this.state.fullscreen && (
                    <MapFilterCol title="Tooltip Preferences">
                        <button
                            className="btn btn-outline btn-secondary mb-0"
                            onClick={() =>
                                this.setState({
                                    editing_preferences: true,
                                })
                            }
                        >
                            Modify
                            <i
                                className="fa fa-cog fixed-plugin-button-nav cursor-pointer ms-2"
                                aria-hidden="true"
                            ></i>
                        </button>
                        {data_preference_modal}
                    </MapFilterCol>
                )}
            </>
        );

        return (
            <>
                <div className="card mb-5">
                    <div className="card-header pb-0">
                        <h5 className="mb-0">{this.props.title}</h5>
                    </div>
                    <div className="card-body">
                        <Loading loaded={this.state.loaded} cover={true}>
                            <MapboxMap
                                fullscreen={this.state.fullscreen}
                                mapFilters={mapFilters}
                                geo_scale={this.state.map_filter_data.geo_scale}
                                style={{ minHeight: "500px" }}
                                map_lookup_data={map_lookup_data}
                                map_lookup_order={data_point_names}
                                map_color_data={map_color_data}
                                invert_colors={invert_colors}
                                data_timestamp={this.state.data_timestamp}
                                toggleSavedMarket={this.toggleSavedMarket}
                                findMarketByRegionID={this.findMarketByRegionID}
                                markets={this.state.markets}
                                geocoding_input={
                                    this.state.map_filter_data["geocoding_input"]
                                }
                                outlineSavedMarkets={!this.props.viewingMyMarketsPage}
                            />
                        </Loading>
                    </div>
                </div>

                <div className="card">
                    <div className="card-header">
                        <div style={{ float: "right" }}>
                            <Button
                                onClick={this.reset_filters}
                                type="gradient-secondary"
                            >
                                Reset Filters
                            </Button>
                            {save_filters_button}
                            {filter_modal}
                        </div>
                        <h5>
                            {`${this.state.map_filter_data["geo_scale"]} Market Data`}
                        </h5>
                        <p>
                            Make the best market choices through our aggregated and
                            filtered MLS dataset.
                        </p>
                    </div>
                    <div className="card-body">
                        <AGGrid
                            rows={this.state.table_rows}
                            columns={this.state.columns}
                            handle_filter_change={this.handle_filter_change}
                            filters={this.state.filter_data}
                            onCellClicked={this.highlight_region}
                        />
                    </div>
                </div>
            </>
        );
    }
}

function MapFilterCol({ title, children }) {
    return (
        <div className="col d-flex flex-column align-items-stretch">
            <h6>{title}</h6>
            {children}
        </div>
    );
}

// Convert new-style region stats format to old-style for backwards
// compatibility. Remove this once we've migrated all the code to use the
// new-style format.
//
// Old style:
// { type: "county", name: "Philadelphia County", "9.5k sqft-1 acre": {...} }
//
// New style:
// { type: "county", name: "Philadelphia County", stats: [
//     { type: "9.5k sqft-1 acre", data: {...} }
// ]}
function newStyleRegionAdapter(region) {
    const stats = region.stats || [];
    for (let stat of stats) {
        region[stat.type] = stat.data;
    }
    return region;
}
