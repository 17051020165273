import { zodResolver } from "@hookform/resolvers/zod";
import { get, isArray, toNumber } from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useDebouncedCallback } from "use-debounce";
import * as z from "zod";
import { fetch, fetchMapboxFeature, formatNumber } from "../../functions";
import { MapboxSuggestion } from "../../functions/mapbox_search";
import {
    INTEGER_NUMBER_VALIDATOR,
    NUMBER_VALIDATOR,
    POSITIVE_NUMBER_VALIDATOR,
    US_ZIP_CODE_VALIDATOR,
} from "../../functions/validation_utils";
import { Accordion } from "../../land_ui/accordion/accordion";
import { Autocomplete, useAutocomplete } from "../../land_ui/autocomplete/autocomplete";
import { Badge } from "../../land_ui/badge/badge";
import { Button } from "../../land_ui/button/button";
import { Icon } from "../../land_ui/icon/icon";
import { Input } from "../../land_ui/input/input";
import { SelectionGroupFormController } from "../../land_ui/selection_group/selection_group";
import { Sidebar, useSidebar } from "../../land_ui/sidebar/sidebar";
import { Toggle } from "../../land_ui/toggle_button/Toggle";
import { Typography } from "../../land_ui/typography/typography";
import { useParcelsSearchRetrieve } from "../../orval/gen/api";
import {
    ParcelExportSkipTraceReportType,
    ParcelsListParams,
} from "../../orval/gen/model";
import { ParcelViewerContext, useMapContext } from "../../pages/parcel_viewer/context";
import {
    AutocompleteResult,
    CountyOption,
    SearchResult,
} from "../../pages/parcel_viewer/types";
import { TagList } from "../tag_list/tag_list";
import { MapFilterParcel } from "./map_filter_parcel_list";
import { SaveFilterParcelsListParams } from "./save_filter_modal";
import { useParcel } from "../../hooks/useParcel";
import {
    findFeatureOnMap,
    searchMapbox,
} from "../../pages/parcel_viewer/controls/search";
import { useMap } from "react-map-gl";

const FormSchema = z.object({
    county: z
        .object(
            {
                id: z.string(),
                label: z.string(),
                inputValue: z.string(),
                data: z.any(),
            },
            { message: "Please select a county" },
        )
        .optional(),

    // Subdivision logic
    subdivisionList: z.array(z.string().optional()).optional(),

    zoningType: z.string().optional(),
    acresFrom: POSITIVE_NUMBER_VALIDATOR,
    acresTo: POSITIVE_NUMBER_VALIDATOR,
    improvementPercentage: NUMBER_VALIDATOR,
    showExcludeZip: z.boolean().optional(),
    owner: z.string().min(5).optional(),
    structure: z.string().optional(),
    other: z.string().optional(),
    skipTracing: z.string().optional(),
    scrubbing: z.string().optional(),

    // ZipCode logic
    zipCode: US_ZIP_CODE_VALIDATOR,
    excludeZipCode: US_ZIP_CODE_VALIDATOR,
    includedZipCodes: z.array(z.string()),
    excludeZipCodes: z.array(z.string()),

    // Structure
    minTotalSqFt: POSITIVE_NUMBER_VALIDATOR,
    maxTotalSqFt: POSITIVE_NUMBER_VALIDATOR,
    minTotalStructureCount: POSITIVE_NUMBER_VALIDATOR,
    maxTotalStructureCount: POSITIVE_NUMBER_VALIDATOR,

    // owner
    outOfStateOwner: z.boolean().optional(),
    outOfCountyOwner: z.boolean().optional(),
    outOfZipOwner: z.boolean().optional(),
    excludeCorpOwner: z.boolean().optional(),
    interFamilyFlag: z.boolean().optional(),
    minOwnershipLengthInMonths: INTEGER_NUMBER_VALIDATOR,
    maxOwnershipLengthInMonths: INTEGER_NUMBER_VALIDATOR,

    // scrubbing
    removeLandLockedParcels: z.boolean().optional(),
    removeDuplicateParcels: z.boolean().optional(),
    deduplicatingType: z.enum(["smaller", "larger"]).optional(),
    maxWetlandCoverage: POSITIVE_NUMBER_VALIDATOR,
    maxFloodCoverage: POSITIVE_NUMBER_VALIDATOR,
    isScrubDnc: z.boolean().optional(),

    // Other
    schoolDistrict: z.string().optional(),

    // Skip Tracing
    skipTracingType: z.enum(["", "standard", "premium"]).nullable().optional(),
});

type FormSchemaType = z.infer<typeof FormSchema>;
const DEFAULT_FORM_VALUES: FormSchemaType = {
    county: null,
    outOfCountyOwner: false,
    outOfStateOwner: false,
    outOfZipOwner: false,
    excludeCorpOwner: false,
    interFamilyFlag: false,
    includedZipCodes: [],
    excludeZipCodes: [],
    subdivisionList: [],
    acresFrom: null,
    acresTo: null,
    improvementPercentage: null,
    minTotalSqFt: null,
    maxTotalSqFt: null,
    minTotalStructureCount: null,
    maxTotalStructureCount: null,
    minOwnershipLengthInMonths: null,
    maxOwnershipLengthInMonths: null,
    showExcludeZip: false,
    removeLandLockedParcels: false,
    removeDuplicateParcels: false,
    maxWetlandCoverage: null,
    maxFloodCoverage: null,
};

interface MapFilterProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
}

export function MapFilter({ isOpen, setIsOpen }: MapFilterProps) {
    const { setSearchResult, savedList, setSavedList } = useMapContext();
    const [isParcelList, setIsParcelList] = useState<boolean>(false);
    const methods = useForm<FormSchemaType>({
        resolver: zodResolver(FormSchema),
        mode: "onBlur",
        defaultValues: getDefaultValues(),
    });
    const formValues = methods.watch();
    const { parcelQueryId } = useParcel();

    const parcelParams = useMemo<SaveFilterParcelsListParams>(() => {
        const countySelectedOption = formValues.county as CountyOption;
        return {
            county: countySelectedOption?.id || "",
            county_label: countySelectedOption?.label || "",
            subdivision: formValues.subdivisionList,
            zips_include: formValues.includedZipCodes,
            zips_exclude: formValues.excludeZipCodes,
            zoning: formValues.zoningType,
            acres_min: formValues.acresFrom,
            acres_max: formValues.acresTo,
            improvement_percentage_max: formValues.improvementPercentage,
            out_of_state_owner: formValues.outOfStateOwner || null,
            out_of_county_owner: formValues.outOfCountyOwner || null,
            out_of_zip_owner: formValues.outOfZipOwner || null,
            exclude_corp_owner: formValues.excludeCorpOwner || null,
            inter_family_flag: formValues.interFamilyFlag || null,
            ownership_length_min: formValues.minOwnershipLengthInMonths,
            ownership_length_max: formValues.maxOwnershipLengthInMonths,
            sq_ft_min: formValues.minTotalSqFt,
            sq_ft_max: formValues.maxTotalSqFt,
            structure_count_min: formValues.minTotalStructureCount,
            structure_count_max: formValues.maxTotalStructureCount,
            state: countySelectedOption?.state,

            year_built_min: null,
            year_built_max: null,
            owner_occupied: null,

            countyOption: countySelectedOption,
        };
    }, [formValues]);

    useEffect(() => {
        if (!!parcelQueryId) {
            setIsOpen(false);
        }
    }, [parcelQueryId, setIsOpen]);

    function resetForm() {
        setSavedList(null);
        setSearchResult(null);
        methods.reset(DEFAULT_FORM_VALUES);
    }

    function getDefaultValues(): FormSchemaType {
        if (!!savedList?.search_filters?.county) {
            const savedFilter = savedList.search_filters;

            return {
                // Acres
                acresFrom: numberOrNull(savedFilter.acres_min),
                acresTo: numberOrNull(savedFilter.acres_max),

                // Square feet
                maxTotalSqFt: numberOrNull(savedFilter.sq_ft_max),
                minTotalSqFt: numberOrNull(savedFilter.sq_ft_min),

                // Structure
                minTotalStructureCount: numberOrNull(savedFilter.structure_count_min),
                maxTotalStructureCount: numberOrNull(savedFilter.structure_count_max),

                improvementPercentage: numberOrNull(
                    savedFilter.improvement_percentage_max,
                ),

                // Owner section
                outOfStateOwner: !!savedFilter.out_of_state_owner,
                outOfCountyOwner: !!savedFilter.out_of_county_owner,
                outOfZipOwner: !!savedFilter.out_of_zip_owner,
                excludeCorpOwner: !!savedFilter.exclude_corp_owner,
                interFamilyFlag: !!savedFilter.inter_family_flag,
                minOwnershipLengthInMonths: numberOrNull(
                    savedFilter.ownership_length_min,
                ),
                maxOwnershipLengthInMonths: numberOrNull(
                    savedFilter.ownership_length_max,
                ),

                // Zipcode section
                includedZipCodes: isArray(savedFilter?.zips_include)
                    ? savedFilter?.zips_include.filter((e) => !!e)
                    : [],

                excludeZipCodes:
                    isArray(savedFilter?.zips_exclude) &&
                    savedFilter?.zips_exclude.length > 0
                        ? savedFilter?.zips_exclude.filter((e) => !!e)
                        : [],

                // County autocomplete
                county: {
                    id: savedFilter.countyOption.id,
                    label: savedFilter.countyOption.label,
                    inputValue: savedFilter.countyOption.label,
                    // @ts-ignore
                    data: savedFilter.countyOption?.data || savedFilter.countyOption,
                },
            };
        }

        return DEFAULT_FORM_VALUES;
    }

    return (
        <FormProvider {...methods}>
            <form>
                <Sidebar
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    removeBackdrop
                    onClose={() => {
                        setSearchResult(null);
                        setSavedList({
                            id: "temp-search-filter",
                            title: "temp-search-filter",
                            search_filters: parcelParams,
                        });
                    }}
                >
                    {/* Export parce list menu  */}
                    {isParcelList ? (
                        <MapFilterParcel
                            parcelParams={parcelParams}
                            setIsParcelList={setIsParcelList}
                            countyOption={methods.watch("county")?.data as CountyOption}
                            resetFilterForm={resetForm}
                            landScrubs={{
                                flood_zone_allowed: numberOrNull(
                                    formValues.maxFloodCoverage,
                                ),
                                wetlands_allowed: numberOrNull(
                                    formValues.maxWetlandCoverage,
                                ),

                                land_locked: formValues.removeLandLockedParcels,
                                scrub_duplicates: formValues.removeDuplicateParcels,
                                size_preference: formValues.deduplicatingType,
                            }}
                            skipTrace={{
                                scrub_dnc: formValues.isScrubDnc,
                                report_type:
                                    // TODO(API-TYPE-ISSUE): Fix the type in the calculate_price api
                                    formValues.skipTracingType as unknown as ParcelExportSkipTraceReportType,
                            }}
                        />
                    ) : (
                        <>
                            <Sidebar.Header preventAutoFocus>
                                Filter Parcels
                            </Sidebar.Header>
                            <Sidebar.Content>
                                <ParcelFormAccordion />
                                <OwnerFormAccordion />
                                <StructureFormAccordion />
                                <ScrubbingFormAccordion />
                                <SkipTracingFormAccordion />
                            </Sidebar.Content>
                            <FormFooter
                                parcelParams={parcelParams}
                                setIsParcelList={setIsParcelList}
                                resetForm={resetForm}
                            />
                        </>
                    )}
                </Sidebar>
            </form>
        </FormProvider>
    );
}

function FormFooter({
    setIsParcelList,
    parcelParams,
    resetForm,
}: {
    setIsParcelList: (value: boolean) => void;
    parcelParams: ParcelsListParams;
    resetForm: () => void;
}) {
    const { isOpen } = useSidebar();
    const {
        handleSubmit,
        watch,
        formState: { errors, isValid },
    } = useFormContext<FormSchemaType>();
    const [showErrorBox, setShowErrorBox] = useState(true);
    const { searchResult, setSearchResult } = useMapContext();
    const { isLoading, refetch: refetchParcelSearch } = useParcelsSearchRetrieve(
        parcelParams,
        {
            query: {
                // Disable this query and only trigger once its refetched
                enabled: false,
            },
        },
    );

    const refetch = useDebouncedCallback(async () => {
        if (!isValid || isLoading) return;
        const searchResult = await refetchParcelSearch();
        if (searchResult) {
            setSearchResult(searchResult.data as unknown as SearchResult);
        }
    }, 500);

    // Listen to changes in the form so we can refetch the data
    const values = watch([
        // Parcel
        "county",
        "subdivisionList",
        "excludeZipCodes",
        "includedZipCodes",
        "acresFrom",
        "acresTo",
        "improvementPercentage",
        // Owner
        "owner",
        "outOfStateOwner",
        "outOfCountyOwner",
        "outOfZipOwner",
        "excludeCorpOwner",
        "interFamilyFlag",
        "minOwnershipLengthInMonths",
        "maxOwnershipLengthInMonths",
        // Structure
        "minTotalSqFt",
        "maxTotalSqFt",
        "minTotalStructureCount",
        "maxTotalStructureCount",
    ]);

    /**
     * Refetch the data when the values change
     */
    useEffect(() => {
        const formValues = watch();
        if (formValues?.county?.id && isOpen) {
            if (
                formValues.acresFrom ||
                formValues.acresTo ||
                formValues.subdivisionList?.length > 0 ||
                formValues.includedZipCodes?.length > 0 ||
                formValues.excludeZipCodes?.length > 0 ||
                formValues.excludeZipCodes?.length > 0
            ) {
                refetch();
            }
        }

        // This is hack to only refetch when the values change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(values)]);
    const hasErrors = Object.keys(errors).length > 0;

    /**
     * Show the error box when there are errors since its dismissible
     */
    useEffect(() => {
        if (hasErrors) {
            setShowErrorBox(true);
        }
    }, [errors, hasErrors]);

    const onSubmit = async () => {
        setIsParcelList(true);
    };

    return (
        <Sidebar.Footer fluid className="lui-p-0">
            {hasErrors && showErrorBox && (
                <div className="lui-border lui-bg-red-200  lui-rounded-lg lui-p-6">
                    <div className="lui-flex lui-gap-2">
                        <Typography>
                            <Icon name="InfoCircle" size="sm" color="red-700" />
                        </Typography>
                        <Typography size="sm" weight="medium">
                            Some fields are missing or contain errors. Please review the
                            highlighted fields.
                        </Typography>
                        <Button
                            variant="base"
                            icon="Close"
                            onClick={() => setShowErrorBox(false)}
                        />
                    </div>
                    <ul className="list-disc  lui-mt-2.5">
                        {Object.keys(errors).map((key) => {
                            return (
                                <Typography
                                    key={key}
                                    size="sm"
                                    variant="li"
                                    color="black"
                                    className="lui-mt-1"
                                >
                                    <span className="lui-border-y-0 lui-border-x-0 lui-border-b lui-border-black lui-border-solid">
                                        {get(fieldKeyToTitle, key, key)}
                                    </span>{" "}
                                    {get(errors, `${key}.message`)}
                                </Typography>
                            );
                        })}
                    </ul>
                </div>
            )}
            <div className="lui-flex lui-gap-4 lui-justify-between lui-p-6">
                <Button className="" variant="inline" onClick={resetForm}>
                    Clear filter
                </Button>
                <div className=" lui-flex lui-justify-end">
                    <Button
                        type="button"
                        onClick={handleSubmit(onSubmit)}
                        disabled={
                            isLoading ||
                            (searchResult?.results &&
                                searchResult?.results?.length == 0)
                        }
                        isLoading={isLoading}
                        className="lui-min-w-40"
                    >
                        {!searchResult && "Filter Parcels"}
                        {searchResult?.results?.length >= 0 &&
                            `Export ${formatNumber(searchResult?.count)} Parcels`}
                    </Button>
                </div>
            </div>
        </Sidebar.Footer>
    );
}

function ParcelFormAccordion() {
    const {
        watch,
        register,
        setValue,
        getValues,
        trigger,
        formState: { errors },
    } = useFormContext<FormSchemaType>();
    const [isOpened, setIsOpened] = useState(true);
    const { current: map } = useMap();
    const { setMapFilter, setSearchResult } = useContext(ParcelViewerContext);

    const countyAutocompleteProps = useAutocomplete<CountyOption>({
        id: "county-search-autocomplete",
        placeholder: "Enter county name...",
        label: "County",
        error: errors.county?.message,
        // Remove all of these "as CountyOption" once we have strictNullChecks enabled in this project
        selectedOption: watch("county") as CountyOption,
        initialOptions: (watch("county") as CountyOption)
            ? [watch("county") as CountyOption]
            : [],
        onSelect: async (selectedItem) => {
            if (!selectedItem) {
                setValue("county", null);
                setSearchResult(null);
                subdivisionAutocompleteProps.clear();
                return;
            }

            const featureCollection = await fetchMapboxFeature(selectedItem.id);
            const feature = featureCollection.features[0];
            if (feature) {
                const result = await findFeatureOnMap(map, feature, 400);

                if (result && result.mapFeature) {
                    const { identifyLayer, idField, mapFeature } = result;

                    // Filter layer by feature ID
                    let newFilter = ["==", idField, mapFeature.id];
                    let inverseFilter = ["!", newFilter];

                    // Apply map filter for shadow layer
                    setMapFilter({ identifyLayer, filter: newFilter, inverseFilter });
                    selectedItem.id = mapFeature.properties.fips;
                    selectedItem.data.id = mapFeature.properties.fips;
                    setValue("county", selectedItem);
                    trigger("county"); // Trigger validation
                }
            }
        },
        onSearch: async (inputValue) => {
            const data = await searchMapbox(inputValue, "district");
            return (
                data?.map((e) => {
                    const formattedName = `${e.name}, ${e.context.region.region_code}`;
                    return {
                        id: e.mapbox_id,
                        label: formattedName,
                        inputValue: formattedName,
                        data: {
                            id: e.mapbox_id,
                            label: formattedName,
                            county: e.name,
                            state: e.context.region.region_code,
                        },
                    };
                }) ?? []
            );
        },
        onReset: () => {
            subdivisionAutocompleteProps.onReset();
        },
    });

    const subdivisionAutocompleteProps = useAutocomplete<MapboxSuggestion>({
        id: "subdivision-search-autocomplete",
        placeholder: "Enter subdivision name...",
        label: "Subdivision",
        onSelect: async (selectedItem) => {
            if (selectedItem?.label) {
                setValue("subdivisionList", [...subdivisionList, selectedItem.label]);
                subdivisionAutocompleteProps.clear();
            }
        },
        onSearch: async (inputValue) => {
            const county = watch("county");

            const params = {
                county: county?.id,
                field: "SubdivisionName",
                query: inputValue,
            };
            const queryString = new URLSearchParams(params).toString();
            const result: AutocompleteResult = await fetch(
                `/api/property/autocomplete/?${queryString}`,
            );

            return result.data
                .map((e) => {
                    return {
                        id: e,
                        label: e,
                        inputValue: e,
                    };
                })
                .filter((e) => !subdivisionList.includes(e.label));
        },
        disabled: !getValues().county,
    });

    const showExcludeZip = watch("showExcludeZip");
    const includedZipCodes = watch("includedZipCodes", []);
    const excludeZipCodes = watch("excludeZipCodes", []);
    const subdivisions = watch("subdivisionList", []);
    const subdivisionList = watch("subdivisionList", []);

    const isError = hasErrorByKeys(errors, [
        "county",
        "zipCode",
        "excludeZipCode",
        "acresFrom",
        "acresTo",
        "improvementPercentage",
    ]);
    const excludeZipCodeRegister = register("excludeZipCode");

    return (
        <Accordion
            isOpen={isOpened}
            onToggle={() => setIsOpened(!isOpened)}
            title={<AccordionFormTitle title="Parcel" isError={isError} />}
        >
            <div className="lui-flex lui-flex-col lui-gap-6">
                <Autocomplete {...countyAutocompleteProps} autoFocus />

                <div>
                    <Autocomplete {...subdivisionAutocompleteProps} />
                    <TagList
                        items={subdivisions}
                        onRemove={(item) => {
                            setValue(
                                "subdivisionList",
                                subdivisionList.filter((i) => i !== item),
                            );
                        }}
                    />
                </div>

                <div>
                    <Input
                        id="zipCode"
                        placeholder="Search ZIP..."
                        label="Include ZIPs"
                        action={
                            showExcludeZip ? null : (
                                <Button
                                    variant="base"
                                    onClick={() => {
                                        setValue("showExcludeZip", true);
                                    }}
                                >
                                    <Typography
                                        variant="span"
                                        color="primary-500"
                                        weight="medium"
                                    >
                                        Exclude ZIPs
                                    </Typography>
                                </Button>
                            )
                        }
                        error={errors.zipCode?.message.toString()}
                        onEnter={async (value) => {
                            const isValid = await trigger("zipCode");
                            if (!isValid || !value) return;
                            if (includedZipCodes.includes(value)) {
                                // Don't add duplicates
                                setValue("zipCode", null);
                            } else {
                                // Add the zip code to the list
                                setValue("includedZipCodes", [
                                    ...includedZipCodes,
                                    value,
                                ]);
                                setValue("zipCode", null);
                            }
                        }}
                        {...register("zipCode")}
                    />

                    <TagList
                        items={includedZipCodes}
                        onRemove={(item) => {
                            setValue(
                                "includedZipCodes",
                                includedZipCodes.filter((i) => i !== item),
                            );
                        }}
                    />
                </div>

                {showExcludeZip && (
                    <div>
                        <Input
                            id="excludeZipCode"
                            placeholder="Search ZIP..."
                            label="Exclude ZIPs"
                            error={errors.excludeZipCode?.message.toString()}
                            onEnter={async (value) => {
                                const isValid = await trigger("excludeZipCode");
                                if (!isValid || !value) return;
                                if (excludeZipCodes.includes(value)) {
                                    // Don't add duplicates
                                    setValue("excludeZipCode", null);
                                } else {
                                    // Add the zip code to the list
                                    setValue("excludeZipCodes", [
                                        ...excludeZipCodes,
                                        value,
                                    ]);
                                    setValue("excludeZipCode", null);
                                }
                            }}
                            // Autofocus as soon as it shows up
                            autoFocus
                            {...excludeZipCodeRegister}
                        />

                        <TagList
                            items={excludeZipCodes}
                            onRemove={(item) => {
                                setValue(
                                    "excludeZipCodes",
                                    excludeZipCodes.filter((i) => i !== item),
                                );
                            }}
                        />
                    </div>
                )}
                <div className="lui-flex lui-gap-6">
                    <Input
                        id="acresFrom"
                        label={fieldKeyToTitle.acresFrom}
                        placeholder="From"
                        error={errors.acresFrom?.message}
                        {...register("acresFrom")}
                    />
                    <Input
                        id="acresTo"
                        placeholder="To"
                        srLabel="Acres To"
                        error={errors.acresTo?.message}
                        {...register("acresTo")}
                    />
                </div>

                <Input
                    id="improvementPercentage"
                    label={fieldKeyToTitle.improvementPercentage}
                    placeholder="%"
                    error={errors.improvementPercentage?.message}
                    {...register("improvementPercentage")}
                />
            </div>
        </Accordion>
    );
}

function StructureFormAccordion() {
    const {
        register,
        formState: { errors },
    } = useFormContext<FormSchemaType>();
    const [isOpened, setIsOpened] = useState(false);

    const isError = Boolean(
        errors.minTotalSqFt?.message ||
            errors.minTotalStructureCount?.message ||
            errors.maxTotalSqFt?.message ||
            errors.maxTotalStructureCount?.message,
    );

    return (
        <Accordion
            isOpen={isOpened}
            onToggle={() => setIsOpened(!isOpened)}
            title={<AccordionFormTitle title="Structure" isError={isError} />}
        >
            <div className="lui-flex lui-flex-col lui-gap-6">
                <div>
                    <Typography variant="span" weight="medium">
                        {fieldKeyToTitle.totalSqFt}
                    </Typography>
                    <div className="lui-flex lui-gap-6 lui-mt-1">
                        <Input
                            id="totalSqFt"
                            error={errors.minTotalSqFt?.message}
                            placeholder="Min"
                            {...register("minTotalSqFt")}
                        />

                        <Input
                            id="totalSqFt"
                            error={errors.maxTotalSqFt?.message}
                            placeholder="Max"
                            {...register("maxTotalSqFt")}
                        />
                    </div>
                </div>
                <div className="lui-flex lui-gap-6">
                    <Input
                        id="totalStructureCount"
                        label={fieldKeyToTitle.totalStructureCount}
                        error={errors.minTotalStructureCount?.message}
                        placeholder="Min"
                        {...register("minTotalStructureCount")}
                    />
                    <Input
                        id="totalStructureCount"
                        srLabel={fieldKeyToTitle.maxTotalStructureCount}
                        error={errors.maxTotalStructureCount?.message}
                        placeholder="Max"
                        {...register("maxTotalStructureCount")}
                    />
                </div>
            </div>
        </Accordion>
    );
}

function OwnerFormAccordion() {
    const {
        register,
        formState: { errors },
        setValue,
    } = useFormContext<FormSchemaType>();
    const [isOpened, setIsOpened] = useState(false);
    const isError = hasErrorByKeys(errors, [
        "outOfStateOwner",
        "outOfCountyOwner",
        "outOfZipOwner",
        "minOwnershipLengthInMonths",
        "maxOwnershipLengthInMonths",
    ]);
    return (
        <Accordion
            isOpen={isOpened}
            onToggle={() => setIsOpened(!isOpened)}
            title={<AccordionFormTitle title="Owner" isError={isError} />}
        >
            <div className="lui-flex lui-flex-col lui-gap-6">
                <Toggle
                    id="outOfStateOwner"
                    label={fieldKeyToTitle.outOfStateOwner}
                    {...register("outOfStateOwner", {
                        onChange: (e) => {
                            if (e.target.checked) {
                                setValue("outOfCountyOwner", false);
                                setValue("outOfZipOwner", false);
                            }
                        },
                    })}
                />
                <Toggle
                    id="outOfCountyOwner"
                    label={fieldKeyToTitle.outOfCountyOwner}
                    {...register("outOfCountyOwner", {
                        onChange: (e) => {
                            if (e.target.checked) {
                                setValue("outOfStateOwner", false);
                                setValue("outOfZipOwner", false);
                            }
                        },
                    })}
                />
                <Toggle
                    id="outOfZipOwner"
                    label={fieldKeyToTitle.outOfZipOwner}
                    {...register("outOfZipOwner", {
                        onChange: (e) => {
                            if (e.target.checked) {
                                setValue("outOfStateOwner", false);
                                setValue("outOfCountyOwner", false);
                            }
                        },
                    })}
                />
                <Toggle
                    id="excludeCorpOwner"
                    label={fieldKeyToTitle.excludeCorpOwner}
                    {...register("excludeCorpOwner")}
                />
                <Toggle
                    id="interFamilyFlag"
                    label={fieldKeyToTitle.interFamilyFlag}
                    {...register("interFamilyFlag")}
                />

                <div>
                    <Typography variant="span" weight="medium">
                        {fieldKeyToTitle.ownershipLengthInMonths}{" "}
                        <Typography variant="span" color="gray-700">
                            (Months)
                        </Typography>
                    </Typography>
                    <div className="lui-flex lui-gap-6 lui-mt-1">
                        <Input
                            id="minOwnershipLengthInMonths"
                            placeholder="Min"
                            {...register("minOwnershipLengthInMonths")}
                        />
                        <Input
                            id="maxOwnershipLengthInMonths"
                            placeholder="Max"
                            {...register("maxOwnershipLengthInMonths")}
                        />
                    </div>
                </div>
            </div>
        </Accordion>
    );
}

function ScrubbingFormAccordion() {
    const {
        register,
        control,
        formState: { errors },
    } = useFormContext<FormSchemaType>();
    const [isOpened, setIsOpened] = useState(false);
    const isError = hasErrorByKeys(errors, [
        "removeLandLockedParcels",
        "removeDuplicateParcels",
        "deduplicatingType",
        "maxWetlandCoverage",
        "maxFloodCoverage",
    ]);
    return (
        <Accordion
            isOpen={isOpened}
            onToggle={() => setIsOpened(!isOpened)}
            title={<AccordionFormTitle title="Scrubbing" isError={isError} />}
        >
            <div className="lui-flex lui-flex-col lui-gap-6">
                <Toggle
                    label={fieldKeyToTitle.removeLandLockedParcels}
                    {...register("removeLandLockedParcels")}
                />
                <Toggle
                    label={fieldKeyToTitle.removeDuplicateParcels}
                    {...register("removeDuplicateParcels")}
                />

                <SelectionGroupFormController
                    control={control}
                    label={fieldKeyToTitle.deduplicatingType}
                    name="deduplicatingType"
                    options={[
                        { title: "Smaller Parcel", id: "smaller" },
                        { title: "Larger Parcel", id: "larger" },
                    ]}
                    horizontal
                />
                <Input
                    id="maxWetlandCoverage"
                    label={fieldKeyToTitle.maxWetlandCoverage}
                    placeholder="%"
                    info="2 credits / record. Empty % will not scrub."
                    error={errors.maxWetlandCoverage?.message}
                    {...register("maxWetlandCoverage")}
                />
                <Input
                    id="maxFloodCoverage"
                    label={fieldKeyToTitle.maxFloodCoverage}
                    placeholder="%"
                    info="2 credits / record. Empty % will not scrub."
                    error={errors.maxFloodCoverage?.message}
                    {...register("maxFloodCoverage")}
                />

                <Typography className="lui-flex lui-gap-2" weight="medium">
                    Road Frontage
                    <Badge variant="danger">COMING SOON</Badge>
                </Typography>
            </div>
        </Accordion>
    );
}

function SkipTracingFormAccordion() {
    const [isOpened, setIsOpened] = useState(false);
    const {
        control,
        register,
        formState: { errors },
    } = useFormContext<FormSchemaType>();
    const isError = hasErrorByKeys(errors, ["skipTracingType"]);

    return (
        <Accordion
            isOpen={isOpened}
            onToggle={() => setIsOpened(!isOpened)}
            title={<AccordionFormTitle title="Skip Tracing" isError={isError} />}
        >
            <SelectionGroupFormController
                control={control}
                label={fieldKeyToTitle.skipTracingType}
                name="skipTracingType"
                options={[
                    {
                        id: "", // The backend has an empty string as the default value
                        title: "No Skipping",
                        subtitle: "FREE",
                    },
                    {
                        id: "standard",
                        title: "Standard",
                        subtitle: "5 Credit / Hit",
                        info: "Includes up to 6 phone numbers and the type of each phone number (mobile, landline, VOIP).",
                    },
                    {
                        id: "premium",
                        title: "Premium",
                        subtitle: "6 Credits / Hit",
                        info: "Includes up to 7 phone numbers, the type of each phone number (mobile, landline, VOIP), age of owner, owner email, relative phone numbers and emails, and higher hit rate (usually > 95%).",
                        children: (
                            <div className="lui-flex lui-justify-between lui-items-center lui-w-full">
                                <div>
                                    <Typography weight="medium" size="xs">
                                        Scrub DNC
                                    </Typography>
                                    <Typography size="xs" color="gray-700">
                                        Remove Do Not Contact. 1 credit / record.
                                    </Typography>
                                </div>
                                <div>
                                    <Toggle {...register("isScrubDnc")} />
                                </div>
                            </div>
                        ),
                    },
                ]}
            />
        </Accordion>
    );
}

function AccordionFormTitle({ title, isError }: { title: string; isError?: boolean }) {
    return (
        <div className="lui-flex lui-items-center lui-gap-1.5">
            <Typography size="lg" weight="bold">
                {title}
            </Typography>
            {isError && (
                <Icon
                    name="InfoCircle"
                    size="sm"
                    color="red-700"
                    className="lui-mr-1"
                />
            )}
        </div>
    );
}

/**
 * Check if there is an error in the form state by list of keys
 * Used to check the section so we can identify which accordion has an error
 */
function hasErrorByKeys(errors: Record<string, any>, keys: string[]): boolean {
    return keys.some((key) => get(errors, `${key}.message`));
}

//  Map field key to title
const fieldKeyToTitle = {
    county: "County",
    zipCode: "ZIP Code",
    excludeZipCode: "Exclude ZIP Code",
    acresFrom: "Acres Range",
    acresTo: "Acres To",
    improvementPercentage: "Max Improvement Percentage",
    ownershipLengthInMonths: "Ownership Length",
    minOwnershipLengthInMonths: "Min Ownership Length",
    maxOwnershipLengthInMonths: "Max Ownership Length",
    totalSqFt: "Total Structure Square Feet",
    minTotalSqFt: "Min Total Structure Footprint",
    maxTotalSqFt: "Max Total Structure Footprint",
    totalStructureCount: "Total Structure Count",
    minTotalStructureCount: "Min Structure Count",
    maxTotalStructureCount: "Max Structure Count",
    outOfStateOwner: "Out of State Owner",
    outOfCountyOwner: "Out of County Owner",
    outOfZipOwner: "Out of ZIP Owner",
    excludeCorpOwner: "Exclude Corporate Owners",
    interFamilyFlag: "Only Inter-Family Transfers",
    removeLandLockedParcels: "Remove Land Locked Parcels",
    removeDuplicateParcels: "Deduplicate Owners",
    deduplicatingType: "Keep Which Parcel When Deduplicating",
    maxWetlandCoverage: "Max Wetland Coverage",
    maxFloodCoverage: "Max Flood Coverage",
    skipTracingType: "Skip Tracing Type",
};

function numberOrNull(val?: number) {
    return !!val ? toNumber(val) : null;
}
