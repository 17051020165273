import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { toast } from "react-toastify";

import { AGGrid } from "components";
import { UserContext } from "context";
import {
    capitalize,
    fetch,
    formatCredits,
    formatDate,
    formatNumber,
    formatPercent,
    parseErrorResponse,
} from "functions";
import { API_URL } from "settings";
import {
    isWorking,
    percentCompleted,
    progressColor,
    showDownloadButton,
} from "./utils";

const INITIAL_TIMEOUT_INTERVAL = 3000;

function Actions({ data }) {
    const { id, result_csv } = data;
    return (
        <div className="d-flex gap-2">
            <ViewButton id={id} />
            {showDownloadButton(data) && <DownloadButton resultCSV={result_csv} />}
        </div>
    );
}

function ViewButton({ id }) {
    return (
        <Link className="btn btn-outline-secondary" to={`/home/skip_tracing/${id}`}>
            View
        </Link>
    );
}

function DownloadButton({ resultCSV }) {
    return (
        <a
            className="btn btn-outline-primary"
            href={`${API_URL}/api/csv_file/${resultCSV}.csv`}
            target="_blank"
            rel="noreferrer"
        >
            Download
        </a>
    );
}

function Progress({ data }) {
    const percent = percentCompleted(data);
    const color = progressColor(data.status);
    return (
        <div className="">
            <span className="me-2 text-xs">{formatPercent(percent)}</span>
            <div style={{ display: "inline-block" }}>
                <div
                    className="progress"
                    style={{ height: "3px", width: "120px", margin: "0" }}
                >
                    <div
                        className={`progress-bar bg-gradient-${color}`}
                        role="progressbar"
                        aria-valuenow={percent}
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: `${percent}%` }}
                    ></div>
                </div>
            </div>
        </div>
    );
}

function Status({ data }) {
    const { status } = data;
    return (
        <span className="badge badge-dot me-4">
            {isWorking(status) ? (
                <LoadingSpinner />
            ) : (
                <i className={`bg-${progressColor(status)}`} />
            )}
            <span className="text-dark text-xs">{capitalize(status)}</span>
        </span>
    );
}

function LoadingSpinner() {
    return (
        <div
            className="spinner-border text-info"
            role="status"
            style={{
                fontSize: "6px",
                width: ".6rem",
                height: ".6rem",
                marginRight: "0.375rem",
            }}
        ></div>
    );
}

export default function SkipTraceList() {
    const { fetchWallet } = useContext(UserContext);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        var timeoutID;
        var updatedAt = -1;

        let timeoutInterval = INITIAL_TIMEOUT_INTERVAL;

        function refresh() {
            fetchWallet();
            fetch("/api/skiptrace/list/")
                .then((data) => {
                    setRows(data);

                    var latest = updatedAt;

                    if (data && data.length) {
                        for (let row of data) {
                            const t = new Date(row.updated_at).getTime();
                            if (t > latest) {
                                latest = t;
                                break;
                            }
                        }
                    }

                    if (latest !== updatedAt) {
                        timeoutInterval = INITIAL_TIMEOUT_INTERVAL;
                    } else {
                        timeoutInterval = Math.round(timeoutInterval * 1.6);
                    }

                    timeoutID = setTimeout(refresh, timeoutInterval);
                    updatedAt = latest;
                })
                .catch((xhr) => {
                    toast.error(parseErrorResponse(xhr));
                });
        }

        refresh();

        return () => {
            if (timeoutID) {
                clearTimeout(timeoutID);
            }
        };
    }, [fetchWallet]);

    const columns = [
        { headerName: "File Name", field: "input_csv_file_name" },
        {
            headerName: "Rows",
            field: "row_count",
            valueFormatter: ({ value }) => formatNumber(value),
        },
        { headerName: "Status", field: "status", cellRenderer: Status },
        {
            headerName: "Progress",
            field: "percent_completed",
            cellRenderer: Progress,
            width: 200,
        },
        {
            headerName: "Hit Rate",
            field: "hit_rate",
            valueFormatter: ({ value }) => formatPercent(value),
        },
        {
            headerName: "Paid",
            field: "price_total",
            valueFormatter: ({ data, value }) =>
                data.paid_at != null ? formatCredits(value) : "",
        },
        {
            headerName: "Upload Date",
            field: "created_at",
            valueFormatter: ({ value }) => formatDate(value),
            filter: true,
            cellDataType: "dateString",
        },
        {
            field: "id",
            headerName: "Actions",
            cellRenderer: Actions,
            width: 275,
        },
    ];

    return (
        <div className="card">
            <div className="card-header pb-0 d-sm-flex justify-content-between">
                <h5>Skip Tracing</h5>
                <Link
                    className="btn bg-gradient-primary mb-0"
                    to="/home/skip_tracing/new"
                >
                    Start a New Skip
                </Link>
            </div>
            <div className="card-body">
                <AGGrid fill_table={true} rows={rows} columns={columns} />
            </div>
        </div>
    );
}
