import { isImpersonationActive } from "functions/ajax";
import Rupt from "rupt";
import { useLocalStorage } from "usehooks-ts";
import { type LandUser } from "./useUser";
import { useCallback } from "react";

const CLIENT_ID = process.env.REACT_APP_PUBLIC_RUPT_CLIENT_ID;
export function useRupt() {
    const [deviceId, setDeviceId, removeDeviceId] = useLocalStorage("ruptDeciceId", "");

    const isRuptEnabled = useCallback(() => {
        // Disable Rupt if impersonation is active
        if (isImpersonationActive()) {
            return false;
        }

        // Disable Rupt if client id is not set
        return CLIENT_ID && CLIENT_ID.length > 0;
    }, []);

    const detachRupt = useCallback(
        (user: LandUser) => {
            if (!isRuptEnabled()) {
                return;
            }

            Rupt.detach({
                client_id: CLIENT_ID,
                user: user.id,
                device: deviceId,
            });

            removeDeviceId();
        },
        [deviceId, isRuptEnabled, removeDeviceId],
    );

    const attachedRupt = useCallback(
        (user: LandUser) => {
            if (!isRuptEnabled()) {
                return;
            }

            Rupt.attach({
                client_id: CLIENT_ID,
                account: user?.id,
                email: user?.email,
            }).then((response) => {
                setDeviceId(response.device_id);
            });
        },
        [isRuptEnabled, setDeviceId],
    );

    return {
        detachRupt,
        attachedRupt,
    };
}
