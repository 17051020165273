import { useCallback, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { BaseLayout, Navbar, Sidebar } from "components";
import { UserContext } from "context";
import { fetch, isLoggedIn, logout, parseErrorResponse, post } from "functions";
import { Loading } from "library";
import { usePostHog } from "posthog-js/react";
import { useRupt } from "../hooks/useRupt";
import { INTERCOM_APP_ID } from "settings";

var SwalWithReact = withReactContent(Swal);

// Call Intercom every minute (with added seconds for wiggle room) to check for
// new messages for Product Tour
const INTERCOM_UPDATE_INTERVAL = 1000 * 64;

export default function InternalLayout() {
    const location = useLocation();
    const navigate = useNavigate();

    const [user, setUser] = useState();
    const [wallet, setWallet] = useState();

    const [sidebarHidden, _setSidebarHidden] = useState(false);
    const [sidebarPinned, setSidebarPinned] = useState(false);
    const { attachedRupt } = useRupt();
    const posthog = usePostHog();

    const fetchWallet = useCallback(async () => {
        try {
            const wallet = await fetch("/user/user/wallet/");
            setWallet(wallet);
        } catch (xhr) {
            toast.error(parseErrorResponse(xhr));
        }
    }, [setWallet]);

    const purchaseCredits = async (amount) => {
        try {
            const transaction = await post("/user/user/wallet/", {
                amount,
            });
            toast.success(transaction.description);
            await fetchWallet();
        } catch (xhr) {
            toast.error(parseErrorResponse(xhr));
            return false;
        }
        return true;
    };

    const giftCredits = async (userID, amount) => {
        try {
            const transaction = await post(`/api/users/${userID}/gift/`, {
                amount,
            });
            toast.success(transaction.description);
        } catch (xhr) {
            toast.error(parseErrorResponse(xhr));
            return false;
        }
        return true;
    };

    const refundCredits = async (userID, transactionID, note) => {
        try {
            const transaction = await post(`/api/users/${userID}/refund/`, {
                transaction_id: transactionID,
                note,
            });
            toast.success(transaction.description);
        } catch (xhr) {
            toast.error(parseErrorResponse(xhr));
            return false;
        }
        return true;
    };

    useEffect(() => {
        var intercomTimeoutID;
        var loadUserTimeoutID;

        // Redirect to login page if no auth token present
        if (!isLoggedIn()) {
            logout();
            return;
        }

        const fetchUser = async () => {
            try {
                const user = await fetch("/user/user/");
                if (user.error) {
                    logout();
                    return;
                }
                setUser(user);
                checkOnboarding(user);
                loadIntercom(user);
                identifyUser(user);
                attachedRupt(user);
            } catch (xhr) {
                toast.error(parseErrorResponse(xhr));
            }
        };

        const checkOnboarding = (user) => {
            Swal.close();
            if (!user.onboarding_stripe_completed) {
                navigate("/create-account/subscription");
            } else if (!user.onboarding_eula_completed) {
                showEULAModal(user);
            } else if (!user.has_paid_subscription) {
                showInactiveSubscriptionModal(user);
            }
        };

        const showInactiveSubscriptionModal = async () => {
            SwalWithReact.fire({
                title: "Doh! There's a problem with your subscription.",
                html: (
                    <p>
                        If you canceled and are looking to resubscribe, contact{" "}
                        <a href="mailto:hello@landinsights.co">hello@landinsights.co</a>
                    </p>
                ),
                icon: "error",
                allowOutsideClick: false,
                allowEscapeKey: false,
                showConfirmButton: false,
                animation: false,
            });

            // Retry load_user in 10 seconds
            loadUserTimeoutID = setTimeout(() => fetchUser(), 1000 * 10);
        };

        const showEULAModal = async () => {
            const html = `
          <p>You just need to agree to our EULA before jumping in.</p>
          <a class="btn btn-link mb-0 text-decoration-underline" href="https://www.landinsights.co/wp-content/uploads/2024/11/Landinsights-User-Agreement-v4-10.29.24.pdf" target="_blank" rel="noreferrer">Open EULA</a>
        `;
            const result = await Swal.fire({
                title: "One Last Thing!",
                input: "checkbox",
                html,
                inputValue: 0,
                inputPlaceholder: "I agree",
                confirmButtonText: "Continue",
                inputValidator: (result) => {
                    return !result && "You must agree to continue";
                },
                allowOutsideClick: false,
                allowEscapeKey: false,
                animation: false,
            });
            // Prevent Swal infinite loop from hot-reloading code changes
            if (result.isDismissed) {
                return;
            }
            try {
                await post("/user/user/", {
                    onboarding_eula_completed: result.value,
                });
            } catch (xhr) {
                toast.error(parseErrorResponse(xhr));
            }
        };

        const loadIntercom = (user) => {
            window.Intercom("boot", {
                api_base: "https://api-iam.intercom.io",
                app_id: INTERCOM_APP_ID,
                name: user.name,
                email: user.email,
            });
            intercomTimeoutID = setTimeout(updateIntercom, INTERCOM_UPDATE_INTERVAL);
        };

        const updateIntercom = () => {
            window.Intercom("update", {
                last_request_at: parseInt(new Date().getTime() / 1000),
            });
            intercomTimeoutID = setTimeout(updateIntercom, INTERCOM_UPDATE_INTERVAL);
        };

        const identifyUser = (user) => {
            window.heap.identify(user.id);
            window.heap.addUserProperties({
                email: user.email,
                name: user.name,
            });
            posthog?.identify(user.id, {
                email: user.email,
                name: user.name,
            });
        };

        fetchUser();
        fetchWallet();
        updateIntercom();

        return () => {
            if (intercomTimeoutID) {
                clearTimeout(intercomTimeoutID);
            }
            if (loadUserTimeoutID) {
                clearTimeout(loadUserTimeoutID);
            }
        };
    }, [navigate, fetchWallet, posthog, attachedRupt]);

    const balance = (wallet?.balance?.cash || 0) + (wallet?.balance?.gift || 0);

    const context = {
        // User info
        user,
        // User functions
        setUser,
        // Wallet info
        balance,
        transactions: wallet?.transactions || [],
        // Wallet functions
        purchaseCredits,
        giftCredits,
        refundCredits,
        fetchWallet,
    };

    let current_path = location.pathname;
    if (current_path.endsWith("/")) {
        current_path = current_path.slice(0, current_path.length - 1);
    }

    current_path = current_path.split("/");
    let current_location = current_path.pop();

    let container_class = "g-sidenav-show";
    let sidebar_class = "";
    if (sidebarHidden) {
        container_class = " g-sidenav-hidden";
        sidebar_class = "collapsed";
    }
    if (sidebarPinned) {
        container_class += " g-sidenav-pinned";
        sidebar_class = "bg-white";
    }

    return (
        <BaseLayout className={container_class}>
            <Loading loaded={!!user}>
                <UserContext.Provider value={context}>
                    <Sidebar hidden={sidebarHidden} className={sidebar_class} />
                    <main className="main-content position-relative border-radius-lg">
                        <Navbar
                            current_path={current_path}
                            current_location={current_location}
                            toggle_sidebar={() => setSidebarPinned(!sidebarPinned)}
                            toggle_sidebar_pin={() => setSidebarPinned(!sidebarPinned)}
                        />
                        <div className="container-fluid p-4">
                            <Outlet />
                        </div>
                    </main>
                </UserContext.Provider>
                <ToastContainer position="bottom-right" autoClose={5000} />
            </Loading>
        </BaseLayout>
    );
}
