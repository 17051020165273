import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { AGGrid } from "components";
import { fetch, formatNumber, parseErrorResponse } from "functions";
import { Loading } from "library";
import { toast } from "react-toastify";

function BrokerageLink({ data }) {
    const { brokerage, brokerage_search_url } = data;
    return (
        <Link
            className="text-decoration-underline"
            to={brokerage_search_url}
            target="_blank"
        >
            {brokerage}
        </Link>
    );
}

function AgentLink({ data }) {
    const { agent, agent_search_url } = data;
    return (
        <Link
            className="text-decoration-underline"
            to={agent_search_url}
            target="_blank"
        >
            {agent}
        </Link>
    );
}

export default function RealtorReportDetail() {
    let { state, county } = useParams();
    const navigate = useNavigate();
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);

    const columns = [
        {
            headerName: "Brokerage",
            field: "brokerage",
            cellRenderer: BrokerageLink,
        },
        {
            headerName: "Sold",
            field: "brokerage_sold",
            valueFormatter: ({ value }) => formatNumber(value),
        },
        {
            headerName: "Active",
            field: "brokerage_active",
            valueFormatter: ({ value }) => formatNumber(value),
        },
        { headerName: "Broker Phone", field: "brokerage_phone" },
        { headerName: "Agent", field: "agent", cellRenderer: AgentLink },
        {
            headerName: "Sold",
            field: "agent_sold",
            valueFormatter: ({ value }) => formatNumber(value),
        },
        {
            headerName: "Active",
            field: "agent_active",
            valueFormatter: ({ value }) => formatNumber(value),
        },
        { headerName: "Agent Phone", field: "agent_phone" },
    ];

    useEffect(() => {
        // Handle case where user manually navigates to: /realtor_report/:state
        if (!county) {
            navigate("/home/realtor_report");
            return;
        }
        fetch(`/analytics/realtor_report/${state}/${county}/`)
            .then((data) => {
                setLoading(false);
                setRows(data || []);
            })
            .catch((xhr) => toast.error(parseErrorResponse(xhr)));
    }, [navigate, state, county]);

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="card">
            <div className="card-header pb-0">
                <h5>{county} Brokers And Realtors</h5>
            </div>
            <div className="card-body">
                {(rows.length && (
                    <AGGrid rows={rows} columns={columns} height="auto" />
                )) ||
                    `No data available.`}
            </div>
        </div>
    );
}
