import { useRef } from "react";
import { Popup } from "react-map-gl";
import { Typography } from "../land_ui/typography/typography";

export interface MapboxPopupProps {
    longitude: number;
    latitude: number;
    title: string;
}
export default function MapboxHoverTooltip({
    title,
    longitude,
    latitude,
}: MapboxPopupProps) {
    const popupRef = useRef<mapboxgl.Popup>();

    return (
        <Popup
            key={`${longitude}-${latitude}`}
            longitude={longitude}
            latitude={latitude}
            offset={10}
            onClose={() => {}}
            closeOnClick={false}
            closeButton={false}
            closeOnMove
            ref={popupRef}
        >
            <div className="lui-rounded-2xl lui-mapbox-hover-tooltip">
                <Typography weight="medium">{title}</Typography>
            </div>
        </Popup>
    );
}
