import { useEffect, useRef, useState } from "react";
import { ErrorMessageBox } from "../../../components/ErrorMessageBox";
import { ExportDetailCard } from "../../../components/parcel_detail/parcel_detail_card";
import { parseFileName } from "../../../functions/string_utils";
import { usePagination } from "../../../hooks/usePagination";
import { Button } from "../../../land_ui/button/button";
import { LoadingSpinner } from "../../../land_ui/loading/loading";
import { Sidebar, useSidebar } from "../../../land_ui/sidebar/sidebar";
import { Typography } from "../../../land_ui/typography/typography";
import { useExportsParcelsList, useExportsRetrieve } from "../../../orval/gen/api";
import { ParcelExportRow } from "../../../orval/gen/model";
import { SearchFilters } from "../types";

interface ExportsMenuItemProps {
    exportId: string;
    goBack?: () => void;
    onClose?: () => void;
}
const PAGE_SIZE_LIMIT = 10;

export function ExportListMenu({ exportId, goBack, onClose }: ExportsMenuItemProps) {
    const ref = useRef();
    const { toggle } = useSidebar();
    const [pageNumber, setPageNumber] = useState(1);
    const [refetchInterval, setRefetchInterval] = useState(3_000);

    const { data: exportData, isFetched } = useExportsRetrieve(exportId, {
        query: {
            refetchInterval: refetchInterval,
        },
    });
    const { data, error, refetch } = useExportsParcelsList(
        exportId,
        {
            page: pageNumber,
            page_size: PAGE_SIZE_LIMIT,
        },
        {
            query: {
                retry: 1,
                queryKey: ["exportsParcelsList", exportId, pageNumber],
                enabled: isFetched && !!exportData?.file,
            },
        },
    );
    const { dataList, isLoading, triggerElement } = usePagination<ParcelExportRow>({
        currentPage: pageNumber,
        onNextPage: setPageNumber,
        totalCount: data?.count,
        data: data?.results,
        root: ref.current,
        error,
    });

    // Stop refetching when the export is ready to download
    useEffect(() => {
        // If the export file is ready, stop refetching
        if (exportData?.file) {
            setRefetchInterval(null);
        }
    }, [exportData, refetch]);

    const searchFilter = exportData?.search_filters as SearchFilters;

    return (
        <>
            <Sidebar.Header onClose={onClose}>
                <div className="lui-flex lui-gap-6">
                    {goBack && (
                        <Button
                            variant="base"
                            icon="LeftArrow"
                            className="lui-flex lui-items-center"
                            onClick={goBack}
                        />
                    )}

                    <div>
                        <Typography
                            size="xl"
                            weight="medium"
                            className="lui-truncate lui-w-60"
                        >
                            {parseFileName(exportData?.filename ?? "")}
                        </Typography>
                        <Typography color="primary-500">
                            {exportData?.total_rows} Parcels
                        </Typography>
                    </div>
                </div>
            </Sidebar.Header>
            <Sidebar.Content className="lui-pb-4" ref={ref}>
                {!!exportData?.file && (
                    <>
                        {dataList?.map((e, i) => {
                            return (
                                <ExportDetailCard
                                    key={`parcel-export-${i}-${e.parcel.APN}`}
                                    parcel={e.parcel}
                                    countyLabel={searchFilter?.county_label}
                                    wetlandsPercent={e.wetlands_percent}
                                    floodZonePercent={e.flood_zone_percent}
                                    onSeeDetailClick={() => {
                                        toggle();
                                    }}
                                />
                            );
                        })}
                        {triggerElement}
                    </>
                )}

                {(isLoading || !exportData?.file) && (
                    <div className="lui-py-5 lui-text-center">
                        <LoadingSpinner />
                    </div>
                )}

                <ErrorMessageBox isShown={!!error}>
                    Something went wrong with the request, please try again later.
                </ErrorMessageBox>
            </Sidebar.Content>

            <Sidebar.Footer className="lui-flex lui-justify-end">
                <Button
                    openInNewTab
                    disabled={!exportData?.file_clean}
                    href={exportData?.file_clean}
                    onClick={goBack}
                >
                    {exportData?.file_clean ? "Download Parcels" : "Export In Progress"}
                </Button>
            </Sidebar.Footer>
        </>
    );
}
