import { useEffect } from "react";

export default function Optionals({ scrubRequest, setScrubRequest, next, prev }) {
    useEffect(() => {
        // Skip to checkout if no applicable features enabled
        const featuresEnabled =
            scrubRequest.duplicates || scrubRequest.wetlands || scrubRequest.flood_zone;
        if (!featuresEnabled) {
            next();
        }
    }, [scrubRequest, next]);

    const dedup_option = scrubRequest.duplicates ? (
        <div className="row mb-3">
            <div className="col-6">
                When deduplicating multiple records, prefer the larger parcel or smaller
                parcel?
            </div>
            <div className="col-6">
                <div className="form-group">
                    <select
                        className="form-control"
                        onChange={(e) =>
                            setScrubRequest({ size_preference: e.target.value })
                        }
                        value={scrubRequest.size_preference}
                    >
                        <option value="larger">Larger Parcel</option>
                        <option value="smaller">Smaller Parcel</option>
                    </select>
                </div>
            </div>
        </div>
    ) : null;

    const wetlands_option = scrubRequest.wetlands ? (
        <div className="row mb-3">
            <div className="col-6">
                What is the maximum % of allowed Wetlands in parcel?
            </div>
            <div className="col-6">
                <NumberInput
                    value={scrubRequest.wetlands_allowed}
                    onChange={(wetlands_allowed) =>
                        setScrubRequest({ wetlands_allowed })
                    }
                />
            </div>
        </div>
    ) : null;

    const flood_zone_option = scrubRequest.flood_zone ? (
        <div className="row mb-3">
            <div className="col-6">
                What is the maximum % of allowed Flood Zone in parcel?
            </div>
            <div className="col-6">
                <NumberInput
                    value={scrubRequest.flood_zone_allowed}
                    onChange={(flood_zone_allowed) =>
                        setScrubRequest({ flood_zone_allowed })
                    }
                />
            </div>
        </div>
    ) : null;

    return (
        <>
            <div className="row mb-5">
                <h5 className="mb-3">Optionals</h5>
                {dedup_option}
                {wetlands_option}
                {flood_zone_option}
            </div>
            <div>
                <button className="btn bg-gradient-dark" onClick={prev}>
                    Previous
                </button>
                <button className="btn bg-gradient-dark float-end" onClick={next}>
                    Next
                </button>
            </div>
        </>
    );
}

function NumberInput({ value, onChange, min, max }) {
    min = min || 0;
    max = max || 100;

    const _onChange = (e) => {
        const n = parseInt(e.target.value, 10) || 0;
        onChange(Math.max(min, Math.min(max, n)));
    };

    return (
        <div className="form-group">
            <input
                type="number"
                className="form-control"
                value={value}
                onChange={_onChange}
                min={0}
                max={100}
            />
        </div>
    );
}
