import { Component } from "react";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

var MyAlert = withReactContent(Swal);

MyAlert = MyAlert.mixin({
    customClass: {
        confirmButton: "btn bg-gradient-success",
        cancelButton: "btn bg-gradient-danger",
    },
    buttonsStyling: false,
});

class Modal extends Component {
    static component_name = "EmptyModal";

    constructor(props) {
        super(props);

        this.on_hide = this.on_hide.bind(this);
        this.fire_alert = this.fire_alert.bind(this);
    }

    componentDidMount() {
        if (this.props.show) {
            this.fire_alert();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.show && !prevProps.show) {
            this.fire_alert();
        }
    }

    componentWillUnmount() {
        this.on_hide();
    }

    fire_alert() {
        MyAlert.fire({
            title: this.props.title || "",
            icon: this.props.icon || "",
            showCloseButton: true,
            showConfirmButton: false,
            html: <div>{this.props.children}</div>,
            width: this.props.width || "32em",
        }).then(() => {
            this.on_hide();
        });
    }

    on_hide() {
        MyAlert.close();

        if (this.props.on_hide) {
            this.props.on_hide();
        }
    }

    render() {
        return null;
    }
}

export default Modal;
