import { useEffect, useState } from "react";

import Swal from "sweetalert2";

import { RefillCreditsModal } from "components";
import { INSUFFICIENT_FUNDS } from "constants";
import {
    formatCredits,
    formatNumber,
    parseErrorResponse,
    pluralize,
    post,
} from "functions";

import { STEP_FEATURES, STEP_HEADERS } from "./steps";

const MINUTES_PER_ROW = (3.0 * 60) / 1000.0;

export default function Checkout({ scrubRequest, submit, goto, errorCode }) {
    const [price, setPrice] = useState();
    const [totalRows, setTotalRows] = useState();

    useEffect(() => {
        const fetchPrice = async () => {
            try {
                const data = await post("/analytics/calculate_price/", scrubRequest);
                const { price, row_summary } = data;

                const skippedRowsBadGPS =
                    (row_summary?.invalid_lat_lng || 0) +
                    (row_summary?.out_of_bounds || 0);

                // Show invalid lat/lng modal
                if (skippedRowsBadGPS > 0) {
                    const result = await Swal.fire({
                        icon: "warning",
                        title: "We Found Some Rows With Bad GPS",
                        text: `We detected ${skippedRowsBadGPS} ${pluralize(
                            "row",
                            skippedRowsBadGPS,
                        )} with bad GPS coordinates (either empty, zero or outside the USA). We've filtered these rows out and you won't be charged for them. Do you want to proceed?`,
                        confirmButtonText: "Yes",
                        showCancelButton: true,
                        cancelButtonText: "No",
                    });
                    if (result.isDismissed) {
                        goto(STEP_HEADERS);
                        return;
                    }
                }

                setPrice(price);
                setTotalRows(row_summary?.total_rows || 0);
            } catch (xhr) {
                await Swal.fire({
                    title: "Error",
                    icon: "error",
                    text: parseErrorResponse(xhr),
                });
                goto(STEP_FEATURES);
            }
        };
        fetchPrice();
    }, [scrubRequest, goto]);

    if (!price) {
        return <h5>Calculating Price...</h5>;
    }

    const timeSaved = () => {
        const totalTimeSaved = totalRows * MINUTES_PER_ROW;
        const hoursSaved = Math.floor(totalTimeSaved / 60);
        const minutesSaved = Math.max(1, Math.floor(totalTimeSaved - hoursSaved * 60));

        const timeSavedString = [];
        if (hoursSaved > 0) {
            timeSavedString.push(`${hoursSaved} hour${hoursSaved === 1 ? "" : "s"}`);
        }
        if (minutesSaved > 0) {
            timeSavedString.push(
                `${minutesSaved} minute${minutesSaved === 1 ? "" : "s"}`,
            );
        }

        return timeSavedString.join(" ");
    };

    const lineItems = [
        {
            name: "Total Number Of Rows",
            value: formatNumber(totalRows),
        },
        { name: "Scrub Duplicates", value: "Free" },
        { name: "Scrub Land Locked", value: formatCredits(price.land_locked_price) },
        { name: "Scrub Wetlands", value: formatCredits(price.wetlands_price) },
        { name: "Scrub Flood Zone", value: formatCredits(price.flood_zone_price) },
    ];

    return (
        <>
            {errorCode === INSUFFICIENT_FUNDS ? (
                <RefillCreditsModal amount={price.total_price} onConfirmed={submit} />
            ) : null}
            <h5>Checkout</h5>
            <div className="col-md-8 mx-auto mb-5">
                <div className="mb-3">
                    {lineItems.map((item) => (
                        <div key={item.name} className="row">
                            <div key={item.name} className="col-6 text-end h6">
                                {item.name}
                            </div>
                            <div className="col-6">{item.value}</div>
                        </div>
                    ))}
                </div>
                <div className="row alert alert-success text-white">
                    <div className="col-6 text-end h6 text-white m-0">
                        Total Hours Saved
                    </div>
                    <div className="col-6">{timeSaved()}</div>
                </div>
                <div className="row alert alert-success text-white">
                    <div className="col-6 text-end h6 text-white m-0">
                        Total Scrub Cost
                    </div>
                    <div className="col-6">{formatCredits(price.total_price)}</div>
                </div>
            </div>
            <div>
                <button
                    className="btn bg-gradient-dark"
                    onClick={() => goto(STEP_FEATURES)}
                >
                    Previous
                </button>
                <button className="btn bg-gradient-dark float-end" onClick={submit}>
                    Start Scrubbing
                </button>
            </div>
        </>
    );
}
