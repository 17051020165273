import React, { useContext, useState, useEffect } from "react";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import { toast } from "react-toastify";
import Swal from "sweetalert2";

import { UserContext } from "context";
import {
    fetch,
    formatNumber,
    formatPercent,
    parseErrorResponse,
    pluralize,
    post,
    userHasPerm,
    zeroPad,
} from "functions";

import { UsersChart, UsersTable, Scraping, BarChart } from "./components";

export default function AdminDashboard() {
    return (
        <div className="card">
            <div className="card-body">
                <Tabs defaultActiveKey="users" className="mb-4">
                    <Tab eventKey="users" title="Users">
                        <UsersTab />
                    </Tab>
                    <Tab eventKey="reports" title="Reports">
                        <ReportsTab />
                    </Tab>
                    <Tab eventKey="metrics" title="Metrics">
                        <MetricsTab />
                    </Tab>
                    <Tab eventKey="scraping" title="Scraping">
                        <ScrapingTab />
                    </Tab>
                    <Tab eventKey="system" title="System">
                        <SystemTab />
                    </Tab>
                </Tabs>
            </div>
        </div>
    );
}

function UsersTab() {
    const [users, setUsers] = useState();

    const fetchUsers = async () => {
        try {
            const data = await fetch("/user/users/");
            setUsers(data.user_json);
        } catch (xhr) {
            toast.error(parseErrorResponse(xhr));
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    if (!users) {
        return <>Loading...</>;
    }

    return <UsersTable users={users} refresh_users={fetchUsers} />;
}

function ReportsTab() {
    const [metrics, setMetrics] = useState();

    useEffect(() => {
        // Fetch Land Scrubbing, Skip Tracing, Realtor Report, and User metrics
        const fetchMetrics = async () => {
            try {
                const metrics = await fetch("/api/reporting/revenue/");
                setMetrics(metrics);
            } catch (xhr) {
                toast.error(parseErrorResponse(xhr));
            }
        };

        fetchMetrics();
    }, []);

    if (!metrics) {
        return <>Loading...</>;
    }

    return (
        <Tabs defaultActiveKey="users" className="mb-4">
            <Tab eventKey="users" title="Users">
                <UsersChart users={metrics.users || {}} />
            </Tab>
            <Tab eventKey="land_scrubbing" title="Land Scrubbing">
                <LandRevenueChart
                    id="landscrubbing"
                    data={metrics.landscrubbing || {}}
                />
            </Tab>
            <Tab eventKey="skip_tracing" title="Skip Tracing">
                <LandRevenueChart id="skiptracing" data={metrics.skiptracing || {}} />
            </Tab>
            <Tab eventKey="realtor_report" title="Realtor Report">
                <LandRevenueChart
                    id="realtor_report"
                    data={metrics.realtor_report || {}}
                />
            </Tab>
        </Tabs>
    );
}

function MetricsTab() {
    const [kpis, setKPIs] = useState();

    useEffect(() => {
        const fetchKPIs = async () => {
            try {
                const kpis = await fetch("/api/reporting/kpi/");
                setKPIs(kpis);
            } catch (xhr) {
                toast.error(parseErrorResponse(xhr));
            }
        };

        fetchKPIs();
    }, []);

    if (!kpis) {
        return <>Loading...</>;
    }

    const count_users = kpis.count_users || 0;
    const count_users_past_24_hours = kpis.count_users_past_24_hours || 0;
    const count_users_past_7_days = kpis.count_users_past_7_days || 0;
    const count_users_past_30_days = kpis.count_users_past_30_days || 0;
    const count_users_past_365_days = kpis.count_users_past_365_days || 0;
    const percent_users_never_scrubbed = kpis.percent_users_never_scrubbed || 0;
    const percent_users_below_monthly_scrub_target =
        kpis.percent_users_below_monthly_scrub_target || 0;
    const percent_users_not_scrub_activated =
        kpis.percent_users_not_scrub_activated || 0;
    const avg_scrubs_all_time = kpis.avg_scrubs_all_time || 0;
    const avg_speed_to_first_scrub = kpis.avg_speed_to_first_scrub || 0;
    const avg_speed_to_scrub_activation = kpis.avg_speed_to_scrub_activation || 0;
    const percent_users_never_skipped = kpis.percent_users_never_skipped || 0;
    const percent_users_below_monthly_skip_target =
        kpis.percent_users_below_monthly_skip_target || 0;
    const percent_users_not_skip_activated = kpis.percent_users_not_skip_activated || 0;
    const avg_skips_all_time = kpis.avg_skips_all_time || 0;
    const avg_speed_to_first_skip = kpis.avg_speed_to_first_skip || 0;
    const avg_speed_to_skip_activation = kpis.avg_speed_to_skip_activation || 0;

    return (
        <>
            <h5 className="mb-4">Customer KPIs</h5>
            <div className="row">
                <Card name="Total Paying Users" value={formatNumber(count_users)} />
                <Card
                    name="New Customers"
                    today={formatNumber(count_users_past_24_hours)}
                    thisWeek={formatNumber(count_users_past_7_days)}
                    thisMonth={formatNumber(count_users_past_30_days)}
                    thisYear={formatNumber(count_users_past_365_days)}
                />
            </div>
            <h5 className="my-4">Scrubbing KPIs</h5>
            <div className="row">
                <Card
                    name="Users Never Scrubbed"
                    value={formatPercent(percent_users_never_scrubbed)}
                />
                <Card
                    name="Users Below Monthly Target (2000)"
                    value={formatPercent(percent_users_below_monthly_scrub_target)}
                />
                <Card
                    name="Users Not Activated"
                    value={formatPercent(percent_users_not_scrub_activated)}
                />
            </div>
            <div className="row">
                <Card
                    name="AVG Total Scrubs / User"
                    value={formatNumber(avg_scrubs_all_time)}
                />
                <Card
                    name="Speed To First Scrub"
                    value={`${formatNumber(avg_speed_to_first_scrub)} ${pluralize(
                        "day",
                        avg_speed_to_first_scrub,
                    )}`}
                />
                <Card
                    name="Speed To Activation (5000)"
                    value={`${formatNumber(avg_speed_to_scrub_activation)} ${pluralize(
                        "day",
                        avg_speed_to_scrub_activation,
                    )}`}
                />
            </div>
            <h5 className="my-4">Skipping KPIs</h5>
            <div className="row">
                <Card
                    name="Users Never Skipped"
                    value={formatPercent(percent_users_never_skipped)}
                />
                <Card
                    name="Users Below Monthly Target (2000)"
                    value={formatPercent(percent_users_below_monthly_skip_target)}
                />
                <Card
                    name="Users Not Activated"
                    value={formatPercent(percent_users_not_skip_activated)}
                />
            </div>
            <div className="row">
                <Card
                    name="AVG Total Skips / User"
                    value={formatNumber(avg_skips_all_time)}
                />
                <Card
                    name="Speed To First Skip"
                    value={`${formatNumber(avg_speed_to_first_skip)} ${pluralize(
                        "day",
                        avg_speed_to_first_skip,
                    )}`}
                />
                <Card
                    name="Speed To Activation (5000)"
                    value={`${formatNumber(avg_speed_to_skip_activation)} ${pluralize(
                        "day",
                        avg_speed_to_skip_activation,
                    )}`}
                />
            </div>
        </>
    );
}

function Card({ name, ...props }) {
    return (
        <div className="col-sm-4 mb-4">
            <div className="card card-outline">
                <div className="card-body p-3">
                    <h6 className="text-sm text-muted">{name}</h6>
                    <CardValue {...props} />
                </div>
            </div>
        </div>
    );
}

function CardValue({ value, today, thisWeek, thisMonth, thisYear }) {
    if (value === undefined) {
        return (
            <div className="row">
                <div className="col">
                    <h5 className="font-weight-bolder mb-0">{today}</h5>
                    <span className="text-sm text-secondary text-nowrap">today</span>
                </div>
                <div className="col">
                    <h5 className="font-weight-bolder mb-0">{thisWeek}</h5>
                    <span className="text-sm text-secondary text-nowrap">
                        this week
                    </span>
                </div>
                <div className="col">
                    <h5 className="font-weight-bolder mb-0">{thisMonth}</h5>
                    <span className="text-sm text-secondary text-nowrap">
                        this month
                    </span>
                </div>
                <div className="col">
                    <h5 className="font-weight-bolder mb-0">{thisYear}</h5>
                    <span className="text-sm text-secondary text-nowrap">
                        this year
                    </span>
                </div>
            </div>
        );
    }
    return <h5 className="font-weight-bolder mb-0">{value}</h5>;
}

function ScrapingTab() {
    const { user } = useContext(UserContext);

    if (!userHasPerm(user, "view_zytescraperequest")) {
        return "Not Authorized";
    }

    return (
        <div className="col-md-12 mb-5">
            <Scraping />
        </div>
    );
}

function SystemTab() {
    const [openSeats, setOpenSeats] = useState();
    const [celeryTasks, setCeleryTasks] = useState();

    const saveSeats = async (new_seats) => {
        try {
            const data = await post("/api/save_seats/", {
                new_seats: new_seats,
            });
            setOpenSeats(data.seats);
        } catch (xhr) {
            toast.error(parseErrorResponse(xhr));
        }
    };

    const showEditSeats = async () => {
        const result = await Swal.fire({
            title: "Enter new seats number",
            input: "number",
            inputValue: openSeats,
            showCancelButton: true,
            inputValidator: (value) => {
                if (!value || value < 0) {
                    return "Please enter a valid seat number";
                }
            },
        });
        if (result.isConfirmed) {
            saveSeats(result.value);
        }
    };

    const fetchOpenSeats = async () => {
        try {
            const data = await fetch("/api/open_seats/");
            setOpenSeats(data.open_seats || 0);
        } catch (xhr) {
            toast.error(parseErrorResponse(xhr));
        }
    };

    const fetchCeleryTasks = async () => {
        try {
            const data = await fetch("/api/celery_tasks/");
            setCeleryTasks(data.tasks_count || 0);
        } catch (xhr) {
            toast.error(parseErrorResponse(xhr));
        }
    };

    useEffect(() => {
        fetchOpenSeats();
        fetchCeleryTasks();
    }, []);

    return (
        <div className="row">
            <div className="col-3 mb-5">
                <div className="card card-outline">
                    <div className="card-body d-flex flex-column align-items-center">
                        <p>Open Seats</p>
                        <h3>{openSeats}</h3>
                        <button
                            className="btn btn-icon bg-gradient-primary d-lg-block mt-3 mb-0"
                            onClick={showEditSeats}
                        >
                            Edit Seats
                            <i
                                className="fas fa-arrow-right ms-1"
                                aria-hidden="true"
                            ></i>
                        </button>
                    </div>
                </div>
            </div>

            <div className="col-3 mb-5">
                <div className="card card-outline">
                    <div className="card-body d-flex flex-column align-items-center">
                        <p>Celery Tasks</p>
                        <h3>{celeryTasks}</h3>
                        <button
                            className="btn btn-icon bg-gradient-primary d-lg-block mt-3 mb-0"
                            onClick={fetchCeleryTasks}
                        >
                            Refresh
                            <i className="fas fa-sync-alt ms-1" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

function LandRevenueChart({ data }) {
    const daily = data.daily || {};
    const yearly = data.yearly || [];
    const monthly = data.monthly || [];
    const weekly = data.weekly || [];

    const yearLabels = yearly.map((row) => row.year);
    const yearData = [
        { label: "Count", data: yearly.map((row) => row.count) },
        { label: "Revenue", data: yearly.map((row) => row.revenue / 100) },
        { label: "Profit", data: yearly.map((row) => row.profit / 100) },
    ];

    const monthLabels = monthly.map((row) => `${row.year}-${zeroPad(row.month, 2)}`);
    const monthData = [
        { label: "Count", data: monthly.map((row) => row.count) },
        { label: "Revenue", data: monthly.map((row) => row.revenue / 100) },
        { label: "Profit", data: monthly.map((row) => row.profit / 100) },
    ];

    const weekLabels = weekly.map((row) => `${row.year}-${zeroPad(row.month, 2)}`);
    const weekData = [
        { label: "Count", data: weekly.map((row) => row.count) },
        { label: "Revenue", data: weekly.map((row) => row.revenue / 100) },
        { label: "Profit", data: weekly.map((row) => row.profit / 100) },
    ];

    const dailyLabels = daily.map(
        (row) => `${row.year}-${zeroPad(row.month, 2)}-${zeroPad(row.day, 2)}`,
    );
    const dailyData = [
        { label: "Count", data: daily.map((row) => row.count) },
        { label: "Revenue", data: daily.map((row) => row.revenue / 100) },
        { label: "Profit", data: daily.map((row) => row.profit / 100) },
    ];

    return (
        <Tabs defaultActiveKey="daily" className="mb-4">
            <Tab eventKey="daily" title="Daily">
                <BarChart labels={dailyLabels} datasets={dailyData} />
            </Tab>
            <Tab eventKey="weekly" title="Weekly">
                <BarChart labels={weekLabels} datasets={weekData} />
            </Tab>
            <Tab eventKey="monthly" title="Monthly">
                <BarChart labels={monthLabels} datasets={monthData} />
            </Tab>
            <Tab eventKey="yearly" title="Yearly">
                <BarChart labels={yearLabels} datasets={yearData} />
            </Tab>
        </Tabs>
    );
}
