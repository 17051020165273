import Swal from "sweetalert2";

import { FLOOD_ZONE_COST, LAND_LOCKED_COST, WETLANDS_COST } from "constants";
import { formatCredits } from "functions";

export default function Features({ scrubRequest, setScrubRequest, next, prev }) {
    const submit = () => {
        const featuresSelected =
            scrubRequest.land_locked ||
            scrubRequest.wetlands ||
            scrubRequest.flood_zone;

        if (!featuresSelected) {
            Swal.fire({
                icon: "error",
                title: "No Scrub Features Selected",
                text: "Please select at least 1 land scrub feature to continue.",
            });
            return;
        }

        next();
    };

    return (
        <>
            <div className="row mb-5">
                <div className="col-12 mb-3">
                    <h5>Choose Features</h5>
                </div>
                <FeatureCheckbox
                    label="Duplicates Scrub"
                    helpText="Free / Row"
                    checked={scrubRequest.duplicates}
                    onChange={(duplicates) => setScrubRequest({ duplicates })}
                />
                <FeatureCheckbox
                    label="Land Locked Scrub"
                    helpText={<>{formatCredits(LAND_LOCKED_COST)} / Row</>}
                    checked={scrubRequest.land_locked}
                    onChange={(land_locked) => setScrubRequest({ land_locked })}
                />
                <FeatureCheckbox
                    label="Wetlands Scrub"
                    helpText={`${formatCredits(WETLANDS_COST)} / Row`}
                    checked={scrubRequest.wetlands}
                    onChange={(wetlands) => setScrubRequest({ wetlands })}
                />
                <FeatureCheckbox
                    label="Flood Zone Scrub"
                    helpText={`${formatCredits(FLOOD_ZONE_COST)} / Row`}
                    checked={scrubRequest.flood_zone}
                    onChange={(flood_zone) => setScrubRequest({ flood_zone })}
                />
            </div>
            <div>
                <button className="btn bg-gradient-dark" onClick={prev}>
                    Previous
                </button>
                <button className="btn bg-gradient-dark float-end" onClick={submit}>
                    Next
                </button>
            </div>
        </>
    );
}

function FeatureCheckbox({ label, checked, onChange, helpText }) {
    const inputID = `checkbox_${label}`;
    return (
        <div className="row">
            <div className="col-4 text-start">
                <label className="form-check-label h6" htmlFor={inputID}>
                    {label}
                </label>
            </div>
            <div className="col-4 text-center">
                <div className="form-group multiselect d-inline-block">
                    <div className="form-check form-switch">
                        <input
                            id={inputID}
                            className="form-check-input"
                            type="checkbox"
                            checked={checked}
                            onChange={(e) => onChange(e.target.checked)}
                        />
                    </div>
                </div>
            </div>
            <div className="col-4 text-start">{helpText}</div>
        </div>
    );
}
