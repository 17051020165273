import clsx from "clsx";
import { isArray, toString } from "lodash";
import { useContext, useState } from "react";
import { SaveFilterParcelsListParams } from "../../../components/map_filter/save_filter_modal";
import { Button } from "../../../land_ui/button/button";
import { Icon } from "../../../land_ui/icon/icon";
import { Popover } from "../../../land_ui/popover/popover";
import { Sidebar, useSidebar } from "../../../land_ui/sidebar/sidebar";
import { Typography } from "../../../land_ui/typography/typography";
import {
    PaginatedParcelSavedListList,
    ParcelSavedList,
} from "../../../orval/gen/model";
import { ParcelViewerContext } from "../context";
import DeleteSaveFilterModal from "./delete_filter_modal";
import { RenameSaveFilterModal } from "./rename_filter_modal";
import { MenuSidebarProps } from "./useMenuSidebar";
import { useParcel } from "../../../hooks/useParcel";

export type VIEW_OPTIONS = "MAIN" | "FILTER" | "EXPORT" | "EXPORT_LIST";
interface TagType {
    label: string;
    info: string;
}
interface FilterMenuItemProps extends MenuSidebarProps {
    savedListsResult?: PaginatedParcelSavedListList;
    onFilterClick: () => void;
}
export function FilterMenuItem({
    dispatch,
    savedListsResult,
    onFilterClick,
}: FilterMenuItemProps) {
    return (
        <>
            <Sidebar.Header>
                <div className="lui-flex lui-gap-6">
                    <Button
                        variant="base"
                        icon="LeftArrow"
                        className="lui-flex lui-items-center"
                        onClick={() => {
                            dispatch({ type: "SET_VIEW", view: "MAIN" });
                        }}
                    />

                    <Typography
                        size="xl"
                        weight="medium"
                        className="lui-flex lui-items-center lui-gap-1"
                    >
                        Saved Filters
                        <Typography
                            size="xl"
                            weight="medium"
                            color="gray-700"
                            className="lui-items-center "
                        >
                            ({savedListsResult?.count || 0})
                        </Typography>
                    </Typography>
                </div>
            </Sidebar.Header>

            <Sidebar.Content>
                <div className="lui-flex  lui-flex-col">
                    {savedListsResult?.results.map((result, i) => {
                        return (
                            <FilterItem
                                key={i}
                                item={result}
                                onFilterClick={onFilterClick}
                            />
                        );
                    })}
                </div>
            </Sidebar.Content>
        </>
    );
}

interface FilterItemProps {
    item: ParcelSavedList;
    onFilterClick: () => void;
}

function FilterItem({ item, onFilterClick }: FilterItemProps) {
    const { setSavedList } = useContext(ParcelViewerContext);
    const { toggle } = useSidebar();
    const [isExpanded, setIsExpanded] = useState(false);
    const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const { removeParcelQuery } = useParcel();
    const openFilter = (result: ParcelSavedList) => {
        toggle();
        setSavedList({
            ...result,
            search_filters: result.search_filters as SaveFilterParcelsListParams,
        });
        onFilterClick();
        removeParcelQuery();
    };

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };
    const searchFilters = item.search_filters as SaveFilterParcelsListParams;
    const tags: TagType[] = [
        (searchFilters?.countyOption?.label || searchFilters?.county) && {
            label: "County",

            info:
                searchFilters?.countyOption?.label ??
                // @ts-ignore only to support legacy filter
                searchFilters?.county_label,
        },
        searchFilters.acres_min && {
            label: "Acres",
            info: `${searchFilters.acres_min}${
                searchFilters.acres_max ? ` to ${searchFilters.acres_max}` : ""
            }`,
        },

        searchFilters.zoning && {
            label: "Zoning Type",
            info: searchFilters.zoning,
        },
        searchFilters.improvement_percentage_max && {
            label: "Improved Percentage",
            info: `${searchFilters.improvement_percentage_max}%`,
        },
        searchFilters.structure_count_max && {
            label: "Structure Count",
            info: toString(searchFilters.structure_count_max),
        },
        searchFilters.ownership_length_min && {
            label: "Ownership Length",
            info: `${searchFilters.ownership_length_min} Months`,
        },
        searchFilters.year_built_max && {
            label: "Year Built",
            info: toString(searchFilters.year_built_max),
        },
        searchFilters.owner_occupied && {
            label: "Owner Occupied",
            info: searchFilters.owner_occupied === "Y" ? "Yes" : "No",
        },
        isArray(searchFilters.zips_include) &&
            searchFilters.zips_include.length > 0 && {
                label: "Zip Codes",
                info: searchFilters.zips_include.join(", "),
            },
        isArray(searchFilters.zips_exclude) &&
            searchFilters.zips_exclude.length > 0 && {
                label: "Exclude Zip Codes",
                info: searchFilters.zips_exclude.join(", "),
            },
        searchFilters.out_of_county_owner && {
            label: "Out of County Owner",
            info: searchFilters.out_of_county_owner ? "Yes" : "No",
        },
        searchFilters.out_of_state_owner && {
            label: "Out of State Owner",
            info: searchFilters.out_of_state_owner ? "Yes" : "No",
        },
        searchFilters.out_of_zip_owner && {
            label: "Out of Zip Owner",
            info: searchFilters.out_of_zip_owner ? "Yes" : "No",
        },
        searchFilters.exclude_corp_owner && {
            label: "Exclude Corp Owner",
            info: searchFilters.exclude_corp_owner ? "Yes" : "No",
        },
        searchFilters.exclude_corp_owner && {
            label: "Only Inter-Family Transfers",
            info: searchFilters.inter_family_flag ? "Yes" : "No",
        },
        isArray(searchFilters.subdivision) &&
            searchFilters.subdivision.length > 0 && {
                label: "Subdivision",
                info: searchFilters.subdivision.join(", "),
            },
    ].filter(Boolean);

    return (
        <div className="lui-p-4 lui-px-6 lui-flex lui-flex-col lui-gap-2.5 lui-border-b-2 lui-border-solid lui-border-gray-50 lui-border-t-0 lui-border-x-0">
            <div className="lui-flex lui-justify-between">
                <div className="lui-flex lui-flex-col lui-gap-1">
                    <Button
                        variant="base"
                        fullWidth
                        className="lui-text-start"
                        onClick={() => openFilter(item)}
                    >
                        <Typography
                            variant="span"
                            size="md"
                            weight="medium"
                            className=" lui-w-full"
                        >
                            {item.title}
                        </Typography>
                    </Button>
                </div>
                <div>
                    <Popover
                        trigger={
                            <Button variant="base" className="lui-bg-none">
                                <Icon name="DotsHorizontal" color="gray-700" />
                            </Button>
                        }
                        dismissOnClick
                        placement="bottom-end"
                        className="lui-bg-white lui-shadow-sm lui-rounded-3xl lui-py-2 lui-min-w-32 lui-overflow-hidden"
                    >
                        <div className="lui-flex lui-flex-col lui-text-start">
                            <Button
                                variant="base"
                                className="lui-text-start lui-py-2 lui-px-4  hover:lui-bg-gray-50 lui-rounded-sm"
                                onClick={() => openFilter(item)}
                            >
                                Open Filter
                            </Button>
                            <Button
                                className="lui-text-start lui-py-2 lui-px-4  hover:lui-bg-gray-50 lui-rounded-sm"
                                variant="base"
                                onClick={() => setIsRenameModalOpen(true)}
                            >
                                Rename
                            </Button>

                            <Button
                                className="lui-text-start lui-py-2 lui-px-4  hover:lui-bg-gray-50 lui-rounded-sm"
                                variant="base"
                                onClick={() => setIsDeleteModalOpen(true)}
                            >
                                Delete
                            </Button>
                        </div>
                    </Popover>

                    <RenameSaveFilterModal
                        isOpen={isRenameModalOpen}
                        setIsOpen={setIsRenameModalOpen}
                        filterId={item.id}
                        filterName={item.title}
                    />

                    <DeleteSaveFilterModal
                        isOpen={isDeleteModalOpen}
                        setIsOpen={setIsDeleteModalOpen}
                        filterId={item.id}
                    />
                </div>
            </div>

            <div className="lui-flex lui-flex-wrap lui-gap-2">
                {tags.slice(0, isExpanded ? tags.length : 3).map((tag, i) => (
                    <Tag key={i} {...tag} />
                ))}
                {tags.length > 3 && !isExpanded && (
                    <Tag label={`+${tags.length - 3} Filters`} onClick={toggleExpand} />
                )}
            </div>
        </div>
    );
}

type TagProps = {
    label: string;
    info?: string;
    onClick?: () => void;
};

function Tag({ label = "--", info, onClick }: TagProps) {
    return (
        <div
            className={clsx(
                "lui-flex lui-gap-1 lui-border lui-border-solid lui-border-gray-100 lui-rounded-full lui-px-3 py-1 lui-w-fit",
                {
                    "lui-cursor-pointer hover:lui-bg-gray-100": onClick,
                },
            )}
            onClick={onClick}
        >
            {label && (
                // todo try to fix extra spacing the is associated with the label
                <Typography variant="span" color="gray-700" size="sm">
                    {label}
                </Typography>
            )}
            {info && (
                <Typography variant="span" size="sm" weight="medium">
                    {info}
                </Typography>
            )}
        </div>
    );
}
