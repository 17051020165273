/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * Land Insights API
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
    DefinedInitialDataOptions,
    DefinedUseQueryResult,
    MutationFunction,
    QueryFunction,
    QueryKey,
    UndefinedInitialDataOptions,
    UseMutationOptions,
    UseMutationResult,
    UseQueryOptions,
    UseQueryResult,
} from "@tanstack/react-query";
import type {
    APIError,
    ExportsListParams,
    ExportsParcelsListParams,
    FullParcel,
    PaginatedFullParcelList,
    PaginatedOwnerSearchResponseList,
    PaginatedParcelExportReadOnlyList,
    PaginatedParcelExportRowList,
    PaginatedParcelSavedListList,
    ParcelExportCreate,
    ParcelExportPriceBreakdown,
    ParcelExportReadOnly,
    ParcelExportUpdate,
    ParcelSavedList,
    ParcelSearchResponse,
    ParcelsListParams,
    ParcelsOwnerSearchListParams,
    ParcelsSearchRetrieveParams,
    PatchedParcelSavedList,
    SavedlistsListParams,
} from "./model";
import { customInstance } from "../axios-instance";

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> =
    (<T>() => T extends X ? 1 : 2) extends <T>() => T extends Y ? 1 : 2 ? A : B;

type WritableKeys<T> = {
    [P in keyof T]-?: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, P>;
}[keyof T];

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
    k: infer I,
) => void
    ? I
    : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
    ? {
          [P in keyof Writable<T>]: T[P] extends object
              ? NonReadonly<NonNullable<T[P]>>
              : T[P];
      }
    : DistributeReadOnlyOverUnions<T>;

export const autocompleteRetrieve = (signal?: AbortSignal) => {
    return customInstance<void>({
        url: `/api/property/autocomplete/`,
        method: "GET",
        signal,
    });
};

export const getAutocompleteRetrieveQueryKey = () => {
    return [`/api/property/autocomplete/`] as const;
};

export const getAutocompleteRetrieveQueryOptions = <
    TData = Awaited<ReturnType<typeof autocompleteRetrieve>>,
    TError = unknown,
>(options?: {
    query?: Partial<
        UseQueryOptions<Awaited<ReturnType<typeof autocompleteRetrieve>>, TError, TData>
    >;
}) => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getAutocompleteRetrieveQueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof autocompleteRetrieve>>> = ({
        signal,
    }) => autocompleteRetrieve(signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof autocompleteRetrieve>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type AutocompleteRetrieveQueryResult = NonNullable<
    Awaited<ReturnType<typeof autocompleteRetrieve>>
>;
export type AutocompleteRetrieveQueryError = unknown;

export function useAutocompleteRetrieve<
    TData = Awaited<ReturnType<typeof autocompleteRetrieve>>,
    TError = unknown,
>(options: {
    query: Partial<
        UseQueryOptions<Awaited<ReturnType<typeof autocompleteRetrieve>>, TError, TData>
    > &
        Pick<
            DefinedInitialDataOptions<
                Awaited<ReturnType<typeof autocompleteRetrieve>>,
                TError,
                TData
            >,
            "initialData"
        >;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useAutocompleteRetrieve<
    TData = Awaited<ReturnType<typeof autocompleteRetrieve>>,
    TError = unknown,
>(options?: {
    query?: Partial<
        UseQueryOptions<Awaited<ReturnType<typeof autocompleteRetrieve>>, TError, TData>
    > &
        Pick<
            UndefinedInitialDataOptions<
                Awaited<ReturnType<typeof autocompleteRetrieve>>,
                TError,
                TData
            >,
            "initialData"
        >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useAutocompleteRetrieve<
    TData = Awaited<ReturnType<typeof autocompleteRetrieve>>,
    TError = unknown,
>(options?: {
    query?: Partial<
        UseQueryOptions<Awaited<ReturnType<typeof autocompleteRetrieve>>, TError, TData>
    >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useAutocompleteRetrieve<
    TData = Awaited<ReturnType<typeof autocompleteRetrieve>>,
    TError = unknown,
>(options?: {
    query?: Partial<
        UseQueryOptions<Awaited<ReturnType<typeof autocompleteRetrieve>>, TError, TData>
    >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = getAutocompleteRetrieveQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
}

export const exportsList = (params?: ExportsListParams, signal?: AbortSignal) => {
    return customInstance<PaginatedParcelExportReadOnlyList>({
        url: `/api/property/exports/`,
        method: "GET",
        params,
        signal,
    });
};

export const getExportsListQueryKey = (params?: ExportsListParams) => {
    return [`/api/property/exports/`, ...(params ? [params] : [])] as const;
};

export const getExportsListQueryOptions = <
    TData = Awaited<ReturnType<typeof exportsList>>,
    TError = unknown,
>(
    params?: ExportsListParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof exportsList>>, TError, TData>
        >;
    },
) => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getExportsListQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof exportsList>>> = ({
        signal,
    }) => exportsList(params, signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof exportsList>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type ExportsListQueryResult = NonNullable<
    Awaited<ReturnType<typeof exportsList>>
>;
export type ExportsListQueryError = unknown;

export function useExportsList<
    TData = Awaited<ReturnType<typeof exportsList>>,
    TError = unknown,
>(
    params: undefined | ExportsListParams,
    options: {
        query: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof exportsList>>, TError, TData>
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof exportsList>>,
                    TError,
                    TData
                >,
                "initialData"
            >;
    },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useExportsList<
    TData = Awaited<ReturnType<typeof exportsList>>,
    TError = unknown,
>(
    params?: ExportsListParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof exportsList>>, TError, TData>
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof exportsList>>,
                    TError,
                    TData
                >,
                "initialData"
            >;
    },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useExportsList<
    TData = Awaited<ReturnType<typeof exportsList>>,
    TError = unknown,
>(
    params?: ExportsListParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof exportsList>>, TError, TData>
        >;
    },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useExportsList<
    TData = Awaited<ReturnType<typeof exportsList>>,
    TError = unknown,
>(
    params?: ExportsListParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof exportsList>>, TError, TData>
        >;
    },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = getExportsListQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
}

export const exportsRetrieve = (id: string, signal?: AbortSignal) => {
    return customInstance<ParcelExportReadOnly>({
        url: `/api/property/exports/${id}/`,
        method: "GET",
        signal,
    });
};

export const getExportsRetrieveQueryKey = (id: string) => {
    return [`/api/property/exports/${id}/`] as const;
};

export const getExportsRetrieveQueryOptions = <
    TData = Awaited<ReturnType<typeof exportsRetrieve>>,
    TError = unknown,
>(
    id: string,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof exportsRetrieve>>, TError, TData>
        >;
    },
) => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getExportsRetrieveQueryKey(id);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof exportsRetrieve>>> = ({
        signal,
    }) => exportsRetrieve(id, signal);

    return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof exportsRetrieve>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type ExportsRetrieveQueryResult = NonNullable<
    Awaited<ReturnType<typeof exportsRetrieve>>
>;
export type ExportsRetrieveQueryError = unknown;

export function useExportsRetrieve<
    TData = Awaited<ReturnType<typeof exportsRetrieve>>,
    TError = unknown,
>(
    id: string,
    options: {
        query: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof exportsRetrieve>>, TError, TData>
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof exportsRetrieve>>,
                    TError,
                    TData
                >,
                "initialData"
            >;
    },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useExportsRetrieve<
    TData = Awaited<ReturnType<typeof exportsRetrieve>>,
    TError = unknown,
>(
    id: string,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof exportsRetrieve>>, TError, TData>
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof exportsRetrieve>>,
                    TError,
                    TData
                >,
                "initialData"
            >;
    },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useExportsRetrieve<
    TData = Awaited<ReturnType<typeof exportsRetrieve>>,
    TError = unknown,
>(
    id: string,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof exportsRetrieve>>, TError, TData>
        >;
    },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useExportsRetrieve<
    TData = Awaited<ReturnType<typeof exportsRetrieve>>,
    TError = unknown,
>(
    id: string,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof exportsRetrieve>>, TError, TData>
        >;
    },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = getExportsRetrieveQueryOptions(id, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
}

export const exportsUpdate = (id: string, parcelExportUpdate: ParcelExportUpdate) => {
    return customInstance<ParcelExportReadOnly>({
        url: `/api/property/exports/${id}/`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        data: parcelExportUpdate,
    });
};

export const getExportsUpdateMutationOptions = <
    TError = APIError,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof exportsUpdate>>,
        TError,
        { id: string; data: ParcelExportUpdate },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof exportsUpdate>>,
    TError,
    { id: string; data: ParcelExportUpdate },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof exportsUpdate>>,
        { id: string; data: ParcelExportUpdate }
    > = (props) => {
        const { id, data } = props ?? {};

        return exportsUpdate(id, data);
    };

    return { mutationFn, ...mutationOptions };
};

export type ExportsUpdateMutationResult = NonNullable<
    Awaited<ReturnType<typeof exportsUpdate>>
>;
export type ExportsUpdateMutationBody = ParcelExportUpdate;
export type ExportsUpdateMutationError = APIError;

export const useExportsUpdate = <TError = APIError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof exportsUpdate>>,
        TError,
        { id: string; data: ParcelExportUpdate },
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof exportsUpdate>>,
    TError,
    { id: string; data: ParcelExportUpdate },
    TContext
> => {
    const mutationOptions = getExportsUpdateMutationOptions(options);

    return useMutation(mutationOptions);
};

export const exportsParcelsList = (
    id: string,
    params?: ExportsParcelsListParams,
    signal?: AbortSignal,
) => {
    return customInstance<PaginatedParcelExportRowList>({
        url: `/api/property/exports/${id}/parcels/`,
        method: "GET",
        params,
        signal,
    });
};

export const getExportsParcelsListQueryKey = (
    id: string,
    params?: ExportsParcelsListParams,
) => {
    return [
        `/api/property/exports/${id}/parcels/`,
        ...(params ? [params] : []),
    ] as const;
};

export const getExportsParcelsListQueryOptions = <
    TData = Awaited<ReturnType<typeof exportsParcelsList>>,
    TError = unknown,
>(
    id: string,
    params?: ExportsParcelsListParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof exportsParcelsList>>,
                TError,
                TData
            >
        >;
    },
) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ?? getExportsParcelsListQueryKey(id, params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof exportsParcelsList>>> = ({
        signal,
    }) => exportsParcelsList(id, params, signal);

    return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof exportsParcelsList>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type ExportsParcelsListQueryResult = NonNullable<
    Awaited<ReturnType<typeof exportsParcelsList>>
>;
export type ExportsParcelsListQueryError = unknown;

export function useExportsParcelsList<
    TData = Awaited<ReturnType<typeof exportsParcelsList>>,
    TError = unknown,
>(
    id: string,
    params: undefined | ExportsParcelsListParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof exportsParcelsList>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof exportsParcelsList>>,
                    TError,
                    TData
                >,
                "initialData"
            >;
    },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useExportsParcelsList<
    TData = Awaited<ReturnType<typeof exportsParcelsList>>,
    TError = unknown,
>(
    id: string,
    params?: ExportsParcelsListParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof exportsParcelsList>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof exportsParcelsList>>,
                    TError,
                    TData
                >,
                "initialData"
            >;
    },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useExportsParcelsList<
    TData = Awaited<ReturnType<typeof exportsParcelsList>>,
    TError = unknown,
>(
    id: string,
    params?: ExportsParcelsListParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof exportsParcelsList>>,
                TError,
                TData
            >
        >;
    },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useExportsParcelsList<
    TData = Awaited<ReturnType<typeof exportsParcelsList>>,
    TError = unknown,
>(
    id: string,
    params?: ExportsParcelsListParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof exportsParcelsList>>,
                TError,
                TData
            >
        >;
    },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = getExportsParcelsListQueryOptions(id, params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
}

export const exportsCalculatePriceCreate = (parcelExportCreate: ParcelExportCreate) => {
    return customInstance<ParcelExportPriceBreakdown>({
        url: `/api/property/exports/calculate_price/`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: parcelExportCreate,
    });
};

export const getExportsCalculatePriceCreateMutationOptions = <
    TError = unknown,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof exportsCalculatePriceCreate>>,
        TError,
        { data: ParcelExportCreate },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof exportsCalculatePriceCreate>>,
    TError,
    { data: ParcelExportCreate },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof exportsCalculatePriceCreate>>,
        { data: ParcelExportCreate }
    > = (props) => {
        const { data } = props ?? {};

        return exportsCalculatePriceCreate(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type ExportsCalculatePriceCreateMutationResult = NonNullable<
    Awaited<ReturnType<typeof exportsCalculatePriceCreate>>
>;
export type ExportsCalculatePriceCreateMutationBody = ParcelExportCreate;
export type ExportsCalculatePriceCreateMutationError = unknown;

export const useExportsCalculatePriceCreate = <
    TError = unknown,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof exportsCalculatePriceCreate>>,
        TError,
        { data: ParcelExportCreate },
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof exportsCalculatePriceCreate>>,
    TError,
    { data: ParcelExportCreate },
    TContext
> => {
    const mutationOptions = getExportsCalculatePriceCreateMutationOptions(options);

    return useMutation(mutationOptions);
};

export const exportsPurchaseCreate = (parcelExportCreate: ParcelExportCreate) => {
    return customInstance<ParcelExportReadOnly>({
        url: `/api/property/exports/purchase/`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: parcelExportCreate,
    });
};

export const getExportsPurchaseCreateMutationOptions = <
    TError = APIError,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof exportsPurchaseCreate>>,
        TError,
        { data: ParcelExportCreate },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof exportsPurchaseCreate>>,
    TError,
    { data: ParcelExportCreate },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof exportsPurchaseCreate>>,
        { data: ParcelExportCreate }
    > = (props) => {
        const { data } = props ?? {};

        return exportsPurchaseCreate(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type ExportsPurchaseCreateMutationResult = NonNullable<
    Awaited<ReturnType<typeof exportsPurchaseCreate>>
>;
export type ExportsPurchaseCreateMutationBody = ParcelExportCreate;
export type ExportsPurchaseCreateMutationError = APIError;

export const useExportsPurchaseCreate = <
    TError = APIError,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof exportsPurchaseCreate>>,
        TError,
        { data: ParcelExportCreate },
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof exportsPurchaseCreate>>,
    TError,
    { data: ParcelExportCreate },
    TContext
> => {
    const mutationOptions = getExportsPurchaseCreateMutationOptions(options);

    return useMutation(mutationOptions);
};

export const fipsLookupRetrieve = (signal?: AbortSignal) => {
    return customInstance<void>({
        url: `/api/property/fips_lookup/`,
        method: "GET",
        signal,
    });
};

export const getFipsLookupRetrieveQueryKey = () => {
    return [`/api/property/fips_lookup/`] as const;
};

export const getFipsLookupRetrieveQueryOptions = <
    TData = Awaited<ReturnType<typeof fipsLookupRetrieve>>,
    TError = unknown,
>(options?: {
    query?: Partial<
        UseQueryOptions<Awaited<ReturnType<typeof fipsLookupRetrieve>>, TError, TData>
    >;
}) => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getFipsLookupRetrieveQueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof fipsLookupRetrieve>>> = ({
        signal,
    }) => fipsLookupRetrieve(signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof fipsLookupRetrieve>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type FipsLookupRetrieveQueryResult = NonNullable<
    Awaited<ReturnType<typeof fipsLookupRetrieve>>
>;
export type FipsLookupRetrieveQueryError = unknown;

export function useFipsLookupRetrieve<
    TData = Awaited<ReturnType<typeof fipsLookupRetrieve>>,
    TError = unknown,
>(options: {
    query: Partial<
        UseQueryOptions<Awaited<ReturnType<typeof fipsLookupRetrieve>>, TError, TData>
    > &
        Pick<
            DefinedInitialDataOptions<
                Awaited<ReturnType<typeof fipsLookupRetrieve>>,
                TError,
                TData
            >,
            "initialData"
        >;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useFipsLookupRetrieve<
    TData = Awaited<ReturnType<typeof fipsLookupRetrieve>>,
    TError = unknown,
>(options?: {
    query?: Partial<
        UseQueryOptions<Awaited<ReturnType<typeof fipsLookupRetrieve>>, TError, TData>
    > &
        Pick<
            UndefinedInitialDataOptions<
                Awaited<ReturnType<typeof fipsLookupRetrieve>>,
                TError,
                TData
            >,
            "initialData"
        >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useFipsLookupRetrieve<
    TData = Awaited<ReturnType<typeof fipsLookupRetrieve>>,
    TError = unknown,
>(options?: {
    query?: Partial<
        UseQueryOptions<Awaited<ReturnType<typeof fipsLookupRetrieve>>, TError, TData>
    >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useFipsLookupRetrieve<
    TData = Awaited<ReturnType<typeof fipsLookupRetrieve>>,
    TError = unknown,
>(options?: {
    query?: Partial<
        UseQueryOptions<Awaited<ReturnType<typeof fipsLookupRetrieve>>, TError, TData>
    >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = getFipsLookupRetrieveQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
}

export const parcelsList = (params: ParcelsListParams, signal?: AbortSignal) => {
    return customInstance<PaginatedFullParcelList>({
        url: `/api/property/parcels/`,
        method: "GET",
        params,
        signal,
    });
};

export const getParcelsListQueryKey = (params: ParcelsListParams) => {
    return [`/api/property/parcels/`, ...(params ? [params] : [])] as const;
};

export const getParcelsListQueryOptions = <
    TData = Awaited<ReturnType<typeof parcelsList>>,
    TError = unknown,
>(
    params: ParcelsListParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof parcelsList>>, TError, TData>
        >;
    },
) => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getParcelsListQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof parcelsList>>> = ({
        signal,
    }) => parcelsList(params, signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof parcelsList>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type ParcelsListQueryResult = NonNullable<
    Awaited<ReturnType<typeof parcelsList>>
>;
export type ParcelsListQueryError = unknown;

export function useParcelsList<
    TData = Awaited<ReturnType<typeof parcelsList>>,
    TError = unknown,
>(
    params: ParcelsListParams,
    options: {
        query: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof parcelsList>>, TError, TData>
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof parcelsList>>,
                    TError,
                    TData
                >,
                "initialData"
            >;
    },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useParcelsList<
    TData = Awaited<ReturnType<typeof parcelsList>>,
    TError = unknown,
>(
    params: ParcelsListParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof parcelsList>>, TError, TData>
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof parcelsList>>,
                    TError,
                    TData
                >,
                "initialData"
            >;
    },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useParcelsList<
    TData = Awaited<ReturnType<typeof parcelsList>>,
    TError = unknown,
>(
    params: ParcelsListParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof parcelsList>>, TError, TData>
        >;
    },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useParcelsList<
    TData = Awaited<ReturnType<typeof parcelsList>>,
    TError = unknown,
>(
    params: ParcelsListParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof parcelsList>>, TError, TData>
        >;
    },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = getParcelsListQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
}

export const parcelsRetrieve = (propertyID: number, signal?: AbortSignal) => {
    return customInstance<FullParcel>({
        url: `/api/property/parcels/${propertyID}/`,
        method: "GET",
        signal,
    });
};

export const getParcelsRetrieveQueryKey = (propertyID: number) => {
    return [`/api/property/parcels/${propertyID}/`] as const;
};

export const getParcelsRetrieveQueryOptions = <
    TData = Awaited<ReturnType<typeof parcelsRetrieve>>,
    TError = unknown,
>(
    propertyID: number,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof parcelsRetrieve>>, TError, TData>
        >;
    },
) => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getParcelsRetrieveQueryKey(propertyID);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof parcelsRetrieve>>> = ({
        signal,
    }) => parcelsRetrieve(propertyID, signal);

    return {
        queryKey,
        queryFn,
        enabled: !!propertyID,
        ...queryOptions,
    } as UseQueryOptions<Awaited<ReturnType<typeof parcelsRetrieve>>, TError, TData> & {
        queryKey: QueryKey;
    };
};

export type ParcelsRetrieveQueryResult = NonNullable<
    Awaited<ReturnType<typeof parcelsRetrieve>>
>;
export type ParcelsRetrieveQueryError = unknown;

export function useParcelsRetrieve<
    TData = Awaited<ReturnType<typeof parcelsRetrieve>>,
    TError = unknown,
>(
    propertyID: number,
    options: {
        query: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof parcelsRetrieve>>, TError, TData>
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof parcelsRetrieve>>,
                    TError,
                    TData
                >,
                "initialData"
            >;
    },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useParcelsRetrieve<
    TData = Awaited<ReturnType<typeof parcelsRetrieve>>,
    TError = unknown,
>(
    propertyID: number,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof parcelsRetrieve>>, TError, TData>
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof parcelsRetrieve>>,
                    TError,
                    TData
                >,
                "initialData"
            >;
    },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useParcelsRetrieve<
    TData = Awaited<ReturnType<typeof parcelsRetrieve>>,
    TError = unknown,
>(
    propertyID: number,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof parcelsRetrieve>>, TError, TData>
        >;
    },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useParcelsRetrieve<
    TData = Awaited<ReturnType<typeof parcelsRetrieve>>,
    TError = unknown,
>(
    propertyID: number,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof parcelsRetrieve>>, TError, TData>
        >;
    },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = getParcelsRetrieveQueryOptions(propertyID, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
}

export const parcelsOwnerSearchList = (
    params: ParcelsOwnerSearchListParams,
    signal?: AbortSignal,
) => {
    return customInstance<PaginatedOwnerSearchResponseList>({
        url: `/api/property/parcels/owner_search/`,
        method: "GET",
        params,
        signal,
    });
};

export const getParcelsOwnerSearchListQueryKey = (
    params: ParcelsOwnerSearchListParams,
) => {
    return [
        `/api/property/parcels/owner_search/`,
        ...(params ? [params] : []),
    ] as const;
};

export const getParcelsOwnerSearchListQueryOptions = <
    TData = Awaited<ReturnType<typeof parcelsOwnerSearchList>>,
    TError = unknown,
>(
    params: ParcelsOwnerSearchListParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof parcelsOwnerSearchList>>,
                TError,
                TData
            >
        >;
    },
) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ?? getParcelsOwnerSearchListQueryKey(params);

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof parcelsOwnerSearchList>>
    > = ({ signal }) => parcelsOwnerSearchList(params, signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof parcelsOwnerSearchList>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type ParcelsOwnerSearchListQueryResult = NonNullable<
    Awaited<ReturnType<typeof parcelsOwnerSearchList>>
>;
export type ParcelsOwnerSearchListQueryError = unknown;

export function useParcelsOwnerSearchList<
    TData = Awaited<ReturnType<typeof parcelsOwnerSearchList>>,
    TError = unknown,
>(
    params: ParcelsOwnerSearchListParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof parcelsOwnerSearchList>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof parcelsOwnerSearchList>>,
                    TError,
                    TData
                >,
                "initialData"
            >;
    },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useParcelsOwnerSearchList<
    TData = Awaited<ReturnType<typeof parcelsOwnerSearchList>>,
    TError = unknown,
>(
    params: ParcelsOwnerSearchListParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof parcelsOwnerSearchList>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof parcelsOwnerSearchList>>,
                    TError,
                    TData
                >,
                "initialData"
            >;
    },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useParcelsOwnerSearchList<
    TData = Awaited<ReturnType<typeof parcelsOwnerSearchList>>,
    TError = unknown,
>(
    params: ParcelsOwnerSearchListParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof parcelsOwnerSearchList>>,
                TError,
                TData
            >
        >;
    },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useParcelsOwnerSearchList<
    TData = Awaited<ReturnType<typeof parcelsOwnerSearchList>>,
    TError = unknown,
>(
    params: ParcelsOwnerSearchListParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof parcelsOwnerSearchList>>,
                TError,
                TData
            >
        >;
    },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = getParcelsOwnerSearchListQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
}

/**
 * Return the least amount of information necessary to render points on
the map for performance.
 */
export const parcelsSearchRetrieve = (
    params: ParcelsSearchRetrieveParams,
    signal?: AbortSignal,
) => {
    return customInstance<ParcelSearchResponse>({
        url: `/api/property/parcels/search/`,
        method: "GET",
        params,
        signal,
    });
};

export const getParcelsSearchRetrieveQueryKey = (
    params: ParcelsSearchRetrieveParams,
) => {
    return [`/api/property/parcels/search/`, ...(params ? [params] : [])] as const;
};

export const getParcelsSearchRetrieveQueryOptions = <
    TData = Awaited<ReturnType<typeof parcelsSearchRetrieve>>,
    TError = unknown,
>(
    params: ParcelsSearchRetrieveParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof parcelsSearchRetrieve>>,
                TError,
                TData
            >
        >;
    },
) => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getParcelsSearchRetrieveQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof parcelsSearchRetrieve>>> = ({
        signal,
    }) => parcelsSearchRetrieve(params, signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof parcelsSearchRetrieve>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type ParcelsSearchRetrieveQueryResult = NonNullable<
    Awaited<ReturnType<typeof parcelsSearchRetrieve>>
>;
export type ParcelsSearchRetrieveQueryError = unknown;

export function useParcelsSearchRetrieve<
    TData = Awaited<ReturnType<typeof parcelsSearchRetrieve>>,
    TError = unknown,
>(
    params: ParcelsSearchRetrieveParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof parcelsSearchRetrieve>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof parcelsSearchRetrieve>>,
                    TError,
                    TData
                >,
                "initialData"
            >;
    },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useParcelsSearchRetrieve<
    TData = Awaited<ReturnType<typeof parcelsSearchRetrieve>>,
    TError = unknown,
>(
    params: ParcelsSearchRetrieveParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof parcelsSearchRetrieve>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof parcelsSearchRetrieve>>,
                    TError,
                    TData
                >,
                "initialData"
            >;
    },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useParcelsSearchRetrieve<
    TData = Awaited<ReturnType<typeof parcelsSearchRetrieve>>,
    TError = unknown,
>(
    params: ParcelsSearchRetrieveParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof parcelsSearchRetrieve>>,
                TError,
                TData
            >
        >;
    },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useParcelsSearchRetrieve<
    TData = Awaited<ReturnType<typeof parcelsSearchRetrieve>>,
    TError = unknown,
>(
    params: ParcelsSearchRetrieveParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof parcelsSearchRetrieve>>,
                TError,
                TData
            >
        >;
    },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = getParcelsSearchRetrieveQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
}

export const savedlistsList = (params?: SavedlistsListParams, signal?: AbortSignal) => {
    return customInstance<PaginatedParcelSavedListList>({
        url: `/api/property/savedlists/`,
        method: "GET",
        params,
        signal,
    });
};

export const getSavedlistsListQueryKey = (params?: SavedlistsListParams) => {
    return [`/api/property/savedlists/`, ...(params ? [params] : [])] as const;
};

export const getSavedlistsListQueryOptions = <
    TData = Awaited<ReturnType<typeof savedlistsList>>,
    TError = unknown,
>(
    params?: SavedlistsListParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof savedlistsList>>, TError, TData>
        >;
    },
) => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getSavedlistsListQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof savedlistsList>>> = ({
        signal,
    }) => savedlistsList(params, signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof savedlistsList>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type SavedlistsListQueryResult = NonNullable<
    Awaited<ReturnType<typeof savedlistsList>>
>;
export type SavedlistsListQueryError = unknown;

export function useSavedlistsList<
    TData = Awaited<ReturnType<typeof savedlistsList>>,
    TError = unknown,
>(
    params: undefined | SavedlistsListParams,
    options: {
        query: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof savedlistsList>>, TError, TData>
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof savedlistsList>>,
                    TError,
                    TData
                >,
                "initialData"
            >;
    },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSavedlistsList<
    TData = Awaited<ReturnType<typeof savedlistsList>>,
    TError = unknown,
>(
    params?: SavedlistsListParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof savedlistsList>>, TError, TData>
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof savedlistsList>>,
                    TError,
                    TData
                >,
                "initialData"
            >;
    },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSavedlistsList<
    TData = Awaited<ReturnType<typeof savedlistsList>>,
    TError = unknown,
>(
    params?: SavedlistsListParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof savedlistsList>>, TError, TData>
        >;
    },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useSavedlistsList<
    TData = Awaited<ReturnType<typeof savedlistsList>>,
    TError = unknown,
>(
    params?: SavedlistsListParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof savedlistsList>>, TError, TData>
        >;
    },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = getSavedlistsListQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
}

export const savedlistsCreate = (parcelSavedList: NonReadonly<ParcelSavedList>) => {
    return customInstance<ParcelSavedList>({
        url: `/api/property/savedlists/`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: parcelSavedList,
    });
};

export const getSavedlistsCreateMutationOptions = <
    TError = unknown,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof savedlistsCreate>>,
        TError,
        { data: NonReadonly<ParcelSavedList> },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof savedlistsCreate>>,
    TError,
    { data: NonReadonly<ParcelSavedList> },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof savedlistsCreate>>,
        { data: NonReadonly<ParcelSavedList> }
    > = (props) => {
        const { data } = props ?? {};

        return savedlistsCreate(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type SavedlistsCreateMutationResult = NonNullable<
    Awaited<ReturnType<typeof savedlistsCreate>>
>;
export type SavedlistsCreateMutationBody = NonReadonly<ParcelSavedList>;
export type SavedlistsCreateMutationError = unknown;

export const useSavedlistsCreate = <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof savedlistsCreate>>,
        TError,
        { data: NonReadonly<ParcelSavedList> },
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof savedlistsCreate>>,
    TError,
    { data: NonReadonly<ParcelSavedList> },
    TContext
> => {
    const mutationOptions = getSavedlistsCreateMutationOptions(options);

    return useMutation(mutationOptions);
};

export const savedlistsRetrieve = (id: string, signal?: AbortSignal) => {
    return customInstance<ParcelSavedList>({
        url: `/api/property/savedlists/${id}/`,
        method: "GET",
        signal,
    });
};

export const getSavedlistsRetrieveQueryKey = (id: string) => {
    return [`/api/property/savedlists/${id}/`] as const;
};

export const getSavedlistsRetrieveQueryOptions = <
    TData = Awaited<ReturnType<typeof savedlistsRetrieve>>,
    TError = unknown,
>(
    id: string,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof savedlistsRetrieve>>,
                TError,
                TData
            >
        >;
    },
) => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getSavedlistsRetrieveQueryKey(id);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof savedlistsRetrieve>>> = ({
        signal,
    }) => savedlistsRetrieve(id, signal);

    return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof savedlistsRetrieve>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type SavedlistsRetrieveQueryResult = NonNullable<
    Awaited<ReturnType<typeof savedlistsRetrieve>>
>;
export type SavedlistsRetrieveQueryError = unknown;

export function useSavedlistsRetrieve<
    TData = Awaited<ReturnType<typeof savedlistsRetrieve>>,
    TError = unknown,
>(
    id: string,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof savedlistsRetrieve>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof savedlistsRetrieve>>,
                    TError,
                    TData
                >,
                "initialData"
            >;
    },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSavedlistsRetrieve<
    TData = Awaited<ReturnType<typeof savedlistsRetrieve>>,
    TError = unknown,
>(
    id: string,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof savedlistsRetrieve>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof savedlistsRetrieve>>,
                    TError,
                    TData
                >,
                "initialData"
            >;
    },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSavedlistsRetrieve<
    TData = Awaited<ReturnType<typeof savedlistsRetrieve>>,
    TError = unknown,
>(
    id: string,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof savedlistsRetrieve>>,
                TError,
                TData
            >
        >;
    },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useSavedlistsRetrieve<
    TData = Awaited<ReturnType<typeof savedlistsRetrieve>>,
    TError = unknown,
>(
    id: string,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof savedlistsRetrieve>>,
                TError,
                TData
            >
        >;
    },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = getSavedlistsRetrieveQueryOptions(id, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
}

export const savedlistsUpdate = (
    id: string,
    parcelSavedList: NonReadonly<ParcelSavedList>,
) => {
    return customInstance<ParcelSavedList>({
        url: `/api/property/savedlists/${id}/`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        data: parcelSavedList,
    });
};

export const getSavedlistsUpdateMutationOptions = <
    TError = unknown,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof savedlistsUpdate>>,
        TError,
        { id: string; data: NonReadonly<ParcelSavedList> },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof savedlistsUpdate>>,
    TError,
    { id: string; data: NonReadonly<ParcelSavedList> },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof savedlistsUpdate>>,
        { id: string; data: NonReadonly<ParcelSavedList> }
    > = (props) => {
        const { id, data } = props ?? {};

        return savedlistsUpdate(id, data);
    };

    return { mutationFn, ...mutationOptions };
};

export type SavedlistsUpdateMutationResult = NonNullable<
    Awaited<ReturnType<typeof savedlistsUpdate>>
>;
export type SavedlistsUpdateMutationBody = NonReadonly<ParcelSavedList>;
export type SavedlistsUpdateMutationError = unknown;

export const useSavedlistsUpdate = <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof savedlistsUpdate>>,
        TError,
        { id: string; data: NonReadonly<ParcelSavedList> },
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof savedlistsUpdate>>,
    TError,
    { id: string; data: NonReadonly<ParcelSavedList> },
    TContext
> => {
    const mutationOptions = getSavedlistsUpdateMutationOptions(options);

    return useMutation(mutationOptions);
};

export const savedlistsPartialUpdate = (
    id: string,
    patchedParcelSavedList: NonReadonly<PatchedParcelSavedList>,
) => {
    return customInstance<ParcelSavedList>({
        url: `/api/property/savedlists/${id}/`,
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        data: patchedParcelSavedList,
    });
};

export const getSavedlistsPartialUpdateMutationOptions = <
    TError = unknown,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof savedlistsPartialUpdate>>,
        TError,
        { id: string; data: NonReadonly<PatchedParcelSavedList> },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof savedlistsPartialUpdate>>,
    TError,
    { id: string; data: NonReadonly<PatchedParcelSavedList> },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof savedlistsPartialUpdate>>,
        { id: string; data: NonReadonly<PatchedParcelSavedList> }
    > = (props) => {
        const { id, data } = props ?? {};

        return savedlistsPartialUpdate(id, data);
    };

    return { mutationFn, ...mutationOptions };
};

export type SavedlistsPartialUpdateMutationResult = NonNullable<
    Awaited<ReturnType<typeof savedlistsPartialUpdate>>
>;
export type SavedlistsPartialUpdateMutationBody = NonReadonly<PatchedParcelSavedList>;
export type SavedlistsPartialUpdateMutationError = unknown;

export const useSavedlistsPartialUpdate = <
    TError = unknown,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof savedlistsPartialUpdate>>,
        TError,
        { id: string; data: NonReadonly<PatchedParcelSavedList> },
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof savedlistsPartialUpdate>>,
    TError,
    { id: string; data: NonReadonly<PatchedParcelSavedList> },
    TContext
> => {
    const mutationOptions = getSavedlistsPartialUpdateMutationOptions(options);

    return useMutation(mutationOptions);
};

export const savedlistsDestroy = (id: string) => {
    return customInstance<void>({
        url: `/api/property/savedlists/${id}/`,
        method: "DELETE",
    });
};

export const getSavedlistsDestroyMutationOptions = <
    TError = unknown,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof savedlistsDestroy>>,
        TError,
        { id: string },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof savedlistsDestroy>>,
    TError,
    { id: string },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof savedlistsDestroy>>,
        { id: string }
    > = (props) => {
        const { id } = props ?? {};

        return savedlistsDestroy(id);
    };

    return { mutationFn, ...mutationOptions };
};

export type SavedlistsDestroyMutationResult = NonNullable<
    Awaited<ReturnType<typeof savedlistsDestroy>>
>;

export type SavedlistsDestroyMutationError = unknown;

export const useSavedlistsDestroy = <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof savedlistsDestroy>>,
        TError,
        { id: string },
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof savedlistsDestroy>>,
    TError,
    { id: string },
    TContext
> => {
    const mutationOptions = getSavedlistsDestroyMutationOptions(options);

    return useMutation(mutationOptions);
};
