import { isNumber } from "lodash";
import { formatNumber } from "../../../functions";
import { parseFileName } from "../../../functions/string_utils";
import { Button } from "../../../land_ui/button/button";
import { Divider } from "../../../land_ui/Divider/Divider";
import { Icon } from "../../../land_ui/icon/icon";
import { Popover } from "../../../land_ui/popover/popover";
import { Sidebar } from "../../../land_ui/sidebar/sidebar";
import { Tooltip } from "../../../land_ui/tooltip/tooltip";
import { Typography } from "../../../land_ui/typography/typography";
import { PaginatedParcelExportReadOnlyList } from "../../../orval/gen/model";
import { PLACEHOLDER_LABEL } from "../utils";
import { MenuSidebarProps } from "./useMenuSidebar";

interface ExportsMenuItemProps extends MenuSidebarProps {
    filterCount: number;
    exportListResult?: PaginatedParcelExportReadOnlyList;
}

export function ExportsMenuItem({
    dispatch,
    filterCount,
    exportListResult,
}: ExportsMenuItemProps) {
    return (
        <>
            <Sidebar.Header>
                <div className="lui-flex lui-gap-6">
                    <Button
                        variant="base"
                        icon="LeftArrow"
                        className="lui-flex lui-items-center"
                        onClick={() => {
                            dispatch({ type: "SET_VIEW", view: "MAIN" });
                        }}
                    />

                    <Typography
                        size="xl"
                        weight="medium"
                        className="lui-flex lui-items-center lui-gap-1"
                    >
                        Exports
                        <Typography
                            size="xl"
                            weight="medium"
                            color="gray-700"
                            className="lui-items-center"
                        >
                            ({filterCount})
                        </Typography>
                    </Typography>
                </div>
            </Sidebar.Header>
            <Sidebar.Content>
                <ExportItem dispatch={dispatch} exportListResult={exportListResult} />
            </Sidebar.Content>
        </>
    );
}

interface ExportItemProps extends MenuSidebarProps {
    exportListResult?: PaginatedParcelExportReadOnlyList;
}

export function ExportItem({ dispatch, exportListResult }: ExportItemProps) {
    return (
        <div>
            {exportListResult?.results.map((result) => {
                return (
                    <div
                        key={result.id}
                        className="lui-p-4 lui-px-6 lui-flex lui-flex-col lui-gap-2.5 lui-border-b lui-border-solid lui-border-gray-50"
                    >
                        <div className="lui-flex lui-justify-between">
                            <div className="lui-flex lui-flex-col lui-gap-2.5">
                                <div className="lui-flex lui-flex-col lui-gap-1">
                                    <Button
                                        variant="base"
                                        onClick={() => {
                                            dispatch({
                                                type: "SET_SELECTED_EXPORT",
                                                export: result,
                                            });
                                            dispatch({
                                                type: "SET_VIEW",
                                                view: "EXPORT_PARCELS_LIST",
                                            });
                                        }}
                                    >
                                        <Typography
                                            variant="span"
                                            size="md"
                                            weight="medium"
                                        >
                                            {parseFileName(result?.filename)}
                                        </Typography>
                                    </Button>
                                    <Typography
                                        variant="span"
                                        size="sm"
                                        weight="bold"
                                        color="primary-500"
                                    >
                                        {formatNumber(result?.total_rows ?? 0)} Parcels
                                    </Typography>
                                </div>

                                <div className="lui-flex lui-gap-3">
                                    {result?.skip_trace?.status === "success" ? (
                                        <ExportStatusLabel
                                            label="Skip hit rate"
                                            status={
                                                !!result?.skip_trace
                                                    ? `${result?.skip_trace?.hit_rate}%`
                                                    : PLACEHOLDER_LABEL
                                            }
                                        />
                                    ) : (
                                        <ExportStatusLabel
                                            label="Skip hit rate"
                                            status={
                                                result?.skip_trace?.status ??
                                                PLACEHOLDER_LABEL
                                            }
                                        />
                                    )}

                                    <Divider />

                                    {result?.land_scrub?.status == "success" ? (
                                        <Tooltip
                                            showArrow
                                            placement="top"
                                            trigger={
                                                <Button
                                                    variant="base"
                                                    className="lui-bg-none"
                                                >
                                                    <Typography
                                                        weight="regular"
                                                        color="gray-700"
                                                        size="sm"
                                                    >
                                                        After scrub{" "}
                                                        <Typography
                                                            variant="span"
                                                            weight="bold"
                                                            size="sm"
                                                        >
                                                            {!!result?.land_scrub
                                                                ? formatNumber(
                                                                      result?.land_scrub
                                                                          .total_clean_rows,
                                                                  )
                                                                : PLACEHOLDER_LABEL}
                                                        </Typography>
                                                    </Typography>
                                                </Button>
                                            }
                                            content={
                                                <div className="lui-flex lui-flex-col lui-gap-2 lui-py-2">
                                                    <Typography
                                                        size="sm"
                                                        weight="bold"
                                                        className="lui-flex lui-gap-5 lui-justify-between"
                                                        color="gray-50"
                                                    >
                                                        <div>
                                                            Maximum wetlands cover
                                                        </div>
                                                        <span className="ml-2">
                                                            {isNumber(
                                                                result?.land_scrub
                                                                    ?.wetlands_allowed,
                                                            )
                                                                ? `${result?.land_scrub?.wetlands_allowed}%`
                                                                : "—"}
                                                        </span>
                                                    </Typography>
                                                    <Typography
                                                        size="sm"
                                                        weight="bold"
                                                        className="lui-flex lui-gap-5  lui-justify-between"
                                                        color="gray-50"
                                                    >
                                                        <div>Maximum flood zone</div>
                                                        <span className="ml-2">
                                                            {isNumber(
                                                                result?.land_scrub
                                                                    ?.flood_zone_allowed,
                                                            )
                                                                ? `${result?.land_scrub?.flood_zone_allowed}%`
                                                                : "—"}
                                                        </span>
                                                    </Typography>
                                                </div>
                                            }
                                        />
                                    ) : (
                                        <ExportStatusLabel
                                            label="After scrub"
                                            status={
                                                result?.land_scrub?.status ??
                                                PLACEHOLDER_LABEL
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="lui-flex lui-items-center">
                                <Popover
                                    trigger={
                                        <Button variant="base" className="lui-bg-none">
                                            <Icon
                                                name="DotsHorizontal"
                                                color="gray-700"
                                            />
                                        </Button>
                                    }
                                    placement="bottom-end"
                                    className="lui-bg-white lui-shadow-sm lui-rounded-xl lui-min-w-32 lui-overflow-hidden"
                                >
                                    <div className="lui-flex lui-flex-col lui-text-start">
                                        <Button
                                            disabled={!result?.file}
                                            openInNewTab
                                            href={result?.file}
                                            variant="base"
                                            className="lui-text-start lui-py-2 lui-px-4 hover:lui-bg-gray-50"
                                        >
                                            Download All{" "}
                                            <Typography
                                                variant="span"
                                                size="sm"
                                                weight="regular"
                                                color="gray-700"
                                            >
                                                {!result?.file ? "(Pending)" : ""}
                                            </Typography>
                                        </Button>
                                        <Button
                                            disabled={!result?.file_clean}
                                            openInNewTab
                                            href={result?.file_clean}
                                            variant="base"
                                            className="lui-text-start lui-py-2 lui-px-4 hover:lui-bg-gray-50"
                                        >
                                            Download Clean{" "}
                                            <Typography
                                                variant="span"
                                                size="sm"
                                                weight="regular"
                                                color="gray-700"
                                            >
                                                {!result?.file_clean ? "(Pending)" : ""}
                                            </Typography>
                                        </Button>
                                    </div>
                                </Popover>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

function ExportStatusLabel({ label, status }: { label: string; status: string }) {
    return (
        <Button variant="base" className="lui-bg-none">
            <Typography weight="regular" color="gray-700" size="sm">
                {label}{" "}
                <Typography
                    variant="span"
                    weight="bold"
                    size="sm"
                    className="lui-capitalize"
                >
                    {status}
                </Typography>
            </Typography>
        </Button>
    );
}
