import { CircleLayer, FillLayer, LineLayer, SymbolLayer } from "react-map-gl";

// Maptiler filters
const ZIP_FILTER = [
    "all",
    ["==", ["get", "level"], 1],
    ["==", ["get", "iso_a2"], "US"],
];
const SURFACE_WATER_FILTER = [
    "any",
    ["in", "river", ["downcase", ["get", "wetland_type"]]],
    ["in", "pond", ["downcase", ["get", "wetland_type"]]],
    ["in", "lake", ["downcase", ["get", "wetland_type"]]],
];

export const roadsOutline: LineLayer = {
    id: "roads_outline",
    type: "line",
    "source-layer": "roads",
    paint: {
        "line-width": 2,
        "line-color": "#fff314",
    },
}; // Selected parcel layer
export const wetlandsFill: FillLayer = {
    id: "wetland_identify",
    type: "fill",
    "source-layer": "wetlands",
    paint: { "fill-color": "#0067c9", "fill-opacity": 0.5 },
};
export const wetlandsOutline: LineLayer = {
    id: "wetlands_outline",
    type: "line",
    "source-layer": "wetlands",
    paint: {
        "line-width": 2,
        "line-color": "#153f76",
    },
};
export const surfaceWaterFill: FillLayer = {
    id: "surface_water_fill",
    type: "fill",
    "source-layer": "wetlands",
    filter: SURFACE_WATER_FILTER,
    paint: {
        "fill-color": "#00a4ff",
        "fill-opacity": 0.7,
    },
};
export const surfaceWaterOutline: LineLayer = {
    id: "surface_water_outline",
    type: "line",
    "source-layer": "wetlands",
    filter: SURFACE_WATER_FILTER,
    paint: {
        "line-width": 2,
        "line-color": "#004a75",
    },
};
export const buildingsOutline: LineLayer = {
    id: "buildings_outline",
    type: "line",
    "source-layer": "buildings",
    paint: {
        "line-width": 1,
        "line-color": "#39ff14",
    },
};
export const compsCircleStyle: CircleLayer = {
    id: "comps-circle",
    type: "circle",
    "source-layer": "comps",
    paint: {
        "circle-color": [
            "case",
            ["==", ["get", "status"], "SOLD"],
            "#11a146",
            ["==", ["get", "status"], "PENDING"],
            "#ec4899",
            ["==", ["get", "status"], "ACTIVE"],
            "#0d99ff",
            "#000000",
        ],
        "circle-radius": 6,
        "circle-stroke-color": "#fff",
        "circle-stroke-width": 2,
    },
};
export const compsLabelStyle: SymbolLayer = {
    id: "comps-label",
    type: "symbol",
    "source-layer": "comps",
    minzoom: 13,
    layout: {
        "text-field": [
            "concat",
            ["get", "lot_size"],
            "ac",
            " | ",
            [
                "to-string",
                // Round PPA and format to 2 decimal places
                ["/", ["round", ["/", ["get", "ppa"], 10]], 100],
            ],

            "k ppa",
        ],
        // Added the max-width since since the text could wrap to new line
        "text-max-width": 25,
        "text-size": 13,
        "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
        "icon-image": [
            "case",
            ["==", ["get", "status"], "SOLD"],
            "rounded_green",
            ["==", ["get", "status"], "PENDING"],
            "rounded_red",
            ["==", ["get", "status"], "ACTIVE"],
            "rounded_blue",
            "rounded-green",
        ],
        "icon-text-fit-padding": [0, 3, 0, 3],

        // Stretch icon (background image) to fit text
        "icon-text-fit": "both",

        // Overlap behavior
        "icon-allow-overlap": false,
        "text-allow-overlap": false,

        // This shows more mls data without overlapping, true
        "icon-ignore-placement": true,
    },
    paint: {
        "text-halo-color": "#f2f2f2",
        "text-halo-width": 0.01,

        "text-color": [
            "case",
            ["==", ["get", "status"], "SOLD"],
            "#fff",
            ["==", ["get", "status"], "PENDING"],
            "#fff",
            ["==", ["get", "status"], "ACTIVE"],
            "#FFFFFF",
            "#fff",
        ],
    },
};
export const floodzone100: FillLayer = {
    id: "floodzone_100_fill",
    type: "fill",
    "source-layer": "floodzones",
    paint: {
        "fill-color": "#004f76",
        "fill-opacity": 0.7,
    },
    filter: [
        "any",
        [
            "all",
            ["!=", "subtype", "0.2 PCT ANNUAL CHANCE FLOOD HAZARD"],
            ["!=", "subtype", "FLOODWAY"],
        ],
        ["!has", "subtype"],
    ],
};
export const floodzone500: FillLayer = {
    id: "floodzone_500_fill",
    type: "fill",
    "source-layer": "floodzones",
    filter: ["==", "subtype", "0.2 PCT ANNUAL CHANCE FLOOD HAZARD"],
    paint: {
        "fill-color": "#00969b",
        "fill-opacity": 0.5,
    },
};
export const floodzoneCoastal: FillLayer = {
    id: "floodzone_coastal_fill",
    type: "fill",
    "source-layer": "floodzones",
    filter: ["==", "type", "VE"],
    paint: {
        "fill-color": "#08306b",
        "fill-opacity": 0.5,
    },
};
export const floodzoneFloodway: FillLayer = {
    id: "floodzone_floodway_fill",
    type: "fill",
    "source-layer": "floodzones",
    filter: ["==", "subtype", "FLOODWAY"],
    paint: {
        "fill-color": "#3995ea",
        "fill-opacity": 0.5,
    },
};
// Transparent layer to enable click-to-identify feature
export const countyIdentify: FillLayer = {
    id: "county_identify",
    type: "fill",
    "source-layer": "county_outlines",
    paint: { "fill-color": "#000000", "fill-opacity": 0 },
};

// Highlight county outline
export const countyHighlightOutline: LineLayer = {
    id: "county_highlight_outline",
    type: "line",
    "source-layer": "county_outlines",
    paint: {
        "line-width": 3,
        "line-color": "#ff00f2",
    },
};
// Highlight county shadow
export const countyHighlightShadow: FillLayer = {
    id: "county_highlight_shadow",
    type: "fill",
    "source-layer": "county_outlines",
    paint: { "fill-color": "#000000", "fill-opacity": 0.5 },
};

export const countyNameLayer: SymbolLayer = {
    id: "county_name",
    type: "symbol",
    "source-layer": "county_names",
    minzoom: 7,
    layout: {
        "text-field": ["concat", ["get", "name"], "\n", "County"],
        "text-size": ["interpolate", ["linear"], ["zoom"], 7, 12, 12, 20],
        "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
        "text-anchor": "center",
    },
    paint: {
        // "text-color": "#FF00F2",
        "text-color": "#fff314",
        "text-halo-color": "#000",
        "text-halo-width": 2,
        "text-halo-blur": 2,
    },
};

export const countyOutline: LineLayer = {
    id: "county_outline",
    type: "line",
    "source-layer": "county_outlines",
    paint: {
        "line-width": [
            "interpolate",
            ["linear"],
            ["zoom"],
            4,
            0.4, // Line width at zoom 12
            18,
            3,
        ],
        "line-color": "#FF00F2",
    },
};

// Transparent layer to enable click-to-identify feature
export const zipIdentify: FillLayer = {
    id: "zip_identify",
    type: "fill",
    "source-layer": "postal",
    paint: { "fill-color": "#000000", "fill-opacity": 0 },
    filter: ZIP_FILTER,
};
// Zip data layer outline
export const zipOutline: LineLayer = {
    id: "zip_outline",
    type: "line",
    "source-layer": "postal",
    paint: {
        "line-width": 1,
        "line-color": "#fff",
    },
    filter: ZIP_FILTER,
};
// Highlight zip outline
export const zipHighlightOutline: LineLayer = {
    id: "zip_highlight_outline",
    type: "line",
    "source-layer": "postal",
    paint: {
        "line-width": 3,
        "line-color": "#ff00f2",
    },
    filter: ZIP_FILTER,
};
// Highlight zip shadow
export const zipHighlightShadow: FillLayer = {
    id: "zip_highlight_shadow",
    type: "fill",
    "source-layer": "postal",
    paint: { "fill-color": "#000000", "fill-opacity": 0.5 },
};
// Transparent layer to enable click-to-identify feature
export const parcelIdentify: FillLayer = {
    id: "parcel_identify",
    type: "fill",
    "source-layer": "parcels",
    paint: { "fill-color": "#000000", "fill-opacity": 0 },
};
// Highlight parcel shadow
export const parcelHighlightShadow: FillLayer = {
    id: "parcel_highlight_shadow",
    type: "fill",
    "source-layer": "parcels",
    paint: { "fill-color": "#000000", "fill-opacity": 0.5 },
};
// Parcel outline layer
export const parcelHighlightOutline: LineLayer = {
    id: "parcel_highlight_line",
    type: "line",
    "source-layer": "parcels",
    paint: {
        "line-width": [
            "interpolate",
            ["linear"],
            ["zoom"],
            12,
            0.5, // Line width at zoom 12
            18,
            [
                "case",
                ["boolean", ["feature-state", "searchResultMatch"], false],
                4, // Line width for search results at zoom 18
                1, // Line width for non-search results at zoom 18
            ],
        ],
        "line-color": [
            "case",
            ["boolean", ["feature-state", "searchResultMatch"], false],
            "#ff00f2", // Line color for search results
            "#ffa500", // Line color for non-search results
        ],
    },
};
export const parcelSelectedOutline: LineLayer = {
    id: "parcel_selected_outline",
    type: "line",
    "source-layer": "parcels",
    paint: {
        "line-width": 4,
        "line-color": "#17c1e8",
    },
};
// Search results cluster layer
export const searchResultsCluster: CircleLayer = {
    id: "search_results_cluster",
    type: "circle",
    filter: ["has", "point_count"],
    paint: {
        "circle-color": [
            "step",
            ["get", "point_count"],
            "#0D99FF",
            100,
            "#0D99FF",
            750,
            "#0D99FF",
        ],
        "circle-radius": [
            "step",
            ["get", "point_count"], // input value
            20, // Radius for 0-100
            100,
            30, // Radius for 100-750
            750,
            40, // Radius for 750+
        ],
    },
};
export const searchResultsClusterLabel: SymbolLayer = {
    id: "search_results_cluster_label",
    type: "symbol",
    filter: ["has", "point_count"],
    layout: {
        "text-field": ["get", "point_count"],
        "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
        "text-size": 13,
    },
    paint: {
        "text-opacity": ["step", ["zoom"], 1, 14, 0],
        "text-color": "#fff",
    },
};
export const ownersLabelStyle: SymbolLayer = {
    id: "owners_label",
    type: "symbol",
    "source-layer": "owners",
    minzoom: 13,
    layout: {
        "text-field": ["get", "formatted_owner_name"],
        "text-size": [
            "interpolate",
            ["linear"],
            ["zoom"],
            13,
            8, // At zoom level 10, text size is 4
            18,
            12, // At zoom level 18, text size is 12
        ],
        "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
        "text-anchor": "center",
    },
    paint: {
        "text-color": "#d0b600",
        "text-halo-color": "#000",
        "text-halo-width": 2,
        "text-halo-blur": 2,
    },
};
