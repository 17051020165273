import { useState } from "react";
import { Link, useParams } from "react-router-dom";

import { parseErrorResponse, post } from "functions";
import { Card, Form, Modal, TextInput } from "library";

export function ResetPasswordRequest() {
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState();

    const resetPassword = async (data) => {
        setError(null);
        setLoading(true);
        try {
            await post("/user/reset_password/", data);
            setSubmitted(true);
        } catch (xhr) {
            setError(parseErrorResponse(xhr));
        }
        setLoading(false);
    };

    return (
        <div className="col-xl-4 col-lg-5 col-md-7 mx-auto">
            <Modal title="Error" icon="error" show={error}>
                {error}
            </Modal>
            <Card>
                <div className="card-header text-center pb-0">
                    <h5>Reset Password</h5>
                </div>
                <div className="card-body">
                    {(submitted &&
                        "Please check your email for instructions on resetting your password. Click the provided link to complete the process.") || (
                        <>
                            <p>
                                Enter your email and we'll send you a link to reset your
                                password.
                            </p>
                            <Form
                                submit={resetPassword}
                                submit_text="Send link to email"
                                submit_button_type="gradient-info"
                                submit_button_class={"w-100 my-4 mb-4"}
                                waiting_for_external_response={loading}
                            >
                                <TextInput
                                    key="email"
                                    name="email"
                                    placeholder="Email"
                                />
                            </Form>
                        </>
                    )}
                </div>
            </Card>
        </div>
    );
}

export function ResetPasswordConfirm() {
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState();
    const { userID, token } = useParams();

    const confirmResetPassword = async (data) => {
        setError(null);
        setLoading(true);
        try {
            await post("/user/reset_password/confirm/", {
                user_id: userID,
                token,
                ...data,
            });
            setSubmitted(true);
        } catch (xhr) {
            setError(parseErrorResponse(xhr));
        }
        setLoading(false);
    };

    return (
        <div className="col-xl-4 col-lg-5 col-md-7 mx-auto">
            <Modal title="Error" icon="error" show={error}>
                {error}
            </Modal>
            <Card>
                <div className="card-header text-center pb-0">
                    <h5>Confirm Reset Password</h5>
                </div>
                <div className="card-body">
                    {(submitted && (
                        <>
                            <p>You reset your password!</p>
                            <Link className="btn btn-primary w-100" to="/login">
                                Login
                            </Link>
                        </>
                    )) || (
                        <Form
                            submit={confirmResetPassword}
                            submit_text="Reset password"
                            submit_button_type="gradient-info"
                            submit_button_class={"w-100 my-4 mb-4"}
                            waiting_for_external_response={loading}
                        >
                            <TextInput
                                type="password"
                                key="new_password"
                                name="new_password"
                                placeholder="New Password"
                            />
                        </Form>
                    )}{" "}
                </div>
            </Card>
        </div>
    );
}
