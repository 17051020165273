import { useState } from "react";

import { FilesDragAndDrop } from "components";
import { parseErrorResponse, postData } from "functions";
import { toast } from "react-toastify";

export default function UploadCSV({
    setScrubRequest,
    setHeaders,
    setExampleItems,
    next,
}) {
    const [file, setFile] = useState();

    const onUpload = (files) => {
        setFile(files[0]);
    };

    const onSubmit = async () => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("fileName", file.name);

        try {
            const data = await postData("/api/csv_file/", formData, {
                // Disable jQuery contentType to generate correct multipart/form-data boundary
                contentType: false,
                // Prevent jQuery from parsing form data
                processData: false,
            });
            console.log(data);
            const { id } = data;
            setHeaders(null);
            setExampleItems(null);

            setScrubRequest({ csv_file: id });
            next();
        } catch (xhr) {
            toast.error(parseErrorResponse(xhr));
        }
    };

    return (
        <div className="form">
            <div className="form-group">
                <div className="mb-5 mx-auto">
                    <FilesDragAndDrop
                        onUpload={onUpload}
                        count={1}
                        formats={["csv"]}
                        containerStyles={{
                            width: "100%",
                            border: "2px dashed #cccccc",
                            padding: "20px",
                        }}
                        openDialogOnClick
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <p>Drag &amp; Drop</p>
                            <p>--- or ---</p>
                            <p>Click to Select File</p>
                        </div>
                    </FilesDragAndDrop>
                </div>
                <div className="d-flex justify-content-center gap-2">
                    <button
                        type="button"
                        className="btn bg-gradient-primary"
                        onClick={onSubmit}
                        disabled={!file}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
}
